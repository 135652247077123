import React, { useState, useEffect } from "react";
import Select from "react-select";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Form,
  FormControl,
  Table,
} from "react-bootstrap";
import FadeInWrapper from "./fade-in-wrapper";
import NumberFormat from "./number-format";
import RevenueDistributionDrilldownPieChart from "./revenue-distribution-drilldown-pie-chart";

import { formatMoneyWithCurrency } from "../helpers/reports-helper";

const DISTRIBUTION_TYPE = {
  GARD: "GOOGLE_AD_REVENUE_DISTRIBTUTION",
  NIRDL: "NET_INCREASED_REVENUE_DISTRIBUTION",
  DTV: "DETAILED_TABLE_VIEW",
};

class RevenueDistributionTable extends React.PureComponent {
  render() {
    const { reports, selectedCurrency } = this.props;
    const yieldSetsGroupByNetwork = _.groupBy(
      reports.yieldSets,
      "gamNetworkId"
    );

    return (
      <div style={{ maxHeight: "246px", overflow: "auto" }}>
        <Table striped bordered size="sm" responsive>
          <thead style={{ fontSize: "14px" }}>
            <tr>
              <th>Name</th>
              <th>Google Ad Rev</th>
              <th>%</th>
              <th>Net Increased Rev</th>
              <th>%</th>
            </tr>
          </thead>
          <tbody style={{ fontSize: "14px" }}>
            {reports.networks.map((n) => {
              return (
                <React.Fragment key={n.id}>
                  <tr>
                    <td>{n.name}</td>
                    <td>
                      {formatMoneyWithCurrency({
                        currency: selectedCurrency,
                        value: n.googleAdRevenue,
                      })}
                    </td>
                    <td>
                      <NumberFormat
                        value={n.googleAdRevenuePercentage}
                        postfix="%"
                      ></NumberFormat>
                    </td>
                    <td>
                      {formatMoneyWithCurrency({
                        currency: selectedCurrency,
                        value: n.netIncreasedRevenue,
                      })}
                    </td>
                    <td>
                      <NumberFormat
                        value={n.netIncreasedRevenuePercentage}
                        postfix="%"
                      ></NumberFormat>
                    </td>
                  </tr>

                  {yieldSetsGroupByNetwork[n.id].map((d) => {
                    return (
                      <tr key={d.id}>
                        <td>{d.name}</td>
                        <td>
                          {formatMoneyWithCurrency({
                            currency: selectedCurrency,
                            value: d.googleAdRevenue,
                          })}
                        </td>
                        <td>
                          <NumberFormat
                            value={d.googleAdRevenuePercentage}
                            postfix="%"
                          ></NumberFormat>
                        </td>
                        <td>
                          {formatMoneyWithCurrency({
                            currency: selectedCurrency,
                            value: d.netIncreasedRevenue,
                          })}
                        </td>
                        <td>
                          <NumberFormat
                            value={d.netIncreasedRevenuePercentage}
                            postfix="%"
                          ></NumberFormat>
                        </td>
                      </tr>
                    );
                  })}
                </React.Fragment>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  }
}

export default RevenueDistributionTable;
