import React, { useState } from "react";
import { Modal, Button, Card, Table } from "react-bootstrap";
import cogoToast from "cogo-toast";
import { useQuery, useMutation } from "react-apollo-hooks";

import { GET_PUBLISHER_DETAIL, UPDATE_PUBLISHER } from "../query/publishers";
import {
  SUBSCRIPTION_STATUS,
  TRIAL_STATUS,
  GAM_LOGO_TYPE
} from "../../common/constants/common";
import PublisherBasicInfo from "../../base/components/publisher-basic-info";
import PublisherSubscriptionPlans from "./publisher-subscription-plans";
import PublisherGamProfile from "../../base/components/publisher-gam-profile";
const invitationUrl = `${window.location.origin}/#/invite/signup`;

function PublisherDetailsModal(props) {
  const publisherId = props.publisherId;
  const isModalShow = props.isModalShow;
  const handleCloseModal = props.handleCloseModal;
  const refreshPublishers = props.refreshPublishers;

  const updatePublisher = useMutation(UPDATE_PUBLISHER);

  let publisher = {};

  let { data, error, loading, refetch } = useQuery(GET_PUBLISHER_DETAIL, {
    variables: {
      pubId: publisherId
    }
  });

  if (data.publishers) {
    const node = _.get(data, "publishers.edges[0].node");
    publisher = transformPublisherData(node);
  }

  function handleUpdatePublisherBasicInfo(pub) {
    let input = {};
    if (pub.name) {
      input.name = pub.name;
    }

    if (pub.websiteUrls) {
      const urls = _.compact(pub.websiteUrls);
      input.websites = urls;
    }

    if (_.has(pub, "geoCountryId")) {
      input.geoCountryId = pub.geoCountryId;
    }

    if (_.has(pub, "googleAccountType")) {
      input.googleAccountTypeId = pub.googleAccountType;
    }

    console.log(pub, input);

    return updatePublisher({
      variables: {
        input,
        pubId: pub.publisherId
      }
    })
      .then(response => {
        if (response) {
          cogoToast.success(
            `Successfully updated ${pub.name || publisher.name}`
          );
          handleRefresh();
        } else {
          throw `We are unable to update publisher info for ${publisher.name}`;
        }
      })
      .catch(err => {
        console.log("EROROOROROROR", err);
        cogoToast.error(`Something wrong! ${err.message}`, {
          hideAfter: 10
        });
      });
  }

  function handleRefresh() {
    refetch();
  }

  return (
    <Modal
      show={isModalShow}
      size="lg"
      onHide={_ => {
        return;
      }}
    >
      <Modal.Header>
        <Modal.Title>
          <small className="text-secondary ml-1">
            Publisher Detail Settings{" "}
          </small>
          {publisher && publisher.name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ backgroundColor: "#F3F5F7" }}>
        {loading && "Loading"}

        {error && `Something wrong happened: ${error}`}

        {publisher && (
          <React.Fragment>
            <PublisherBasicInfo
              canShowEditButton={true}
              publisher={publisher}
              handleSubmit={handleUpdatePublisherBasicInfo}
            />
            <div className="m-3" />

            {publisher.isRegistered && (
              <React.Fragment>
                <PublisherSubscriptionPlans
                  publisher={publisher}
                  refreshPublisherDetails={handleRefresh}
                />
                <div className="m-3" />
              </React.Fragment>
            )}

            <PublisherGamProfile
              canShowEditButton={!publisher.isRegistered}
              publisher={publisher}
              handleSubmit={handleUpdatePublisherBasicInfo}
            />
          </React.Fragment>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="pl-4 pr-4"
          variant="secondary"
          onClick={() => {
            handleCloseModal();
            refreshPublishers();
          }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function getSubscriptionStatus(publisher) {
  // SUBSCRIBED, NOT_SUBSCRIBED
  const accountType = _.get(publisher, "accountType");
  if (accountType === "ACTIVE") {
    return SUBSCRIPTION_STATUS.SUBSCRIBED;
  }

  return SUBSCRIPTION_STATUS.NOT_SUBSCRIBED;
}

function getTrialStatus(publisher) {
  // TRIAL, TRIAL_EXPIRED
  const accountType = _.get(publisher, "accountType");
  if (accountType === "TRIAL_EXPIRED") {
    return TRIAL_STATUS.TRIAL_EXPIRED;
  }

  const remainingDays = _.get(
    publisher,
    "accountStatus.trialStatus.remainingDays"
  );
  if (remainingDays > 0) {
    return TRIAL_STATUS.TRIAL;
  }
}

function transformPublisherData(node) {
  const isInFreeTrial =
    _.get(node, "accountStatus.trialStatus.remainingDays") > 0;

  let trialInfo = {
    remainingDays: _.get(node, "accountStatus.trialStatus.remainingDays"),
    billableStartDate: _.get(
      node,
      "accountStatus.trialStatus.billableStartDate"
    )
  };
  const geoCountryName = i18n(_.get(node, "geoCountry.name"));
  const geoCountryId = _.get(node, "geoCountry.countryId");

  let publisher = {
    publisherId: node.publisherId,
    name: node.name,
    isRegistered: node.registered === "REGISTERED",
    createdAt: node.createdAt,
    geoCountryName,
    geoCountryId,

    invitationLink: `${invitationUrl}?${node.invitationLinkQuery}`,
    websiteLinks: node.websites,
    isInFreeTrial,
    trialInfo: trialInfo,
    trialStatus: getTrialStatus(node),
    subscriptionStatus: getSubscriptionStatus(node),
    accountType: node.accountType
  };

  switch (node.googleAccountType) {
    case "AD_MANAGER": {
      publisher.gamAccountName = "Basic Ad Manager";
      publisher.gamLogoType = GAM_LOGO_TYPE.AD_MANAGER;
      publisher.hasBillingSection = false;
      break;
    }

    case "INVOICED_AD_MANAGER": {
      publisher.gamAccountName = "Invoiced Ad Manager";
      publisher.gamLogoType = GAM_LOGO_TYPE.AD_MANAGER;
      publisher.hasBillingSection = true;
      break;
    }

    case "AD_MANAGER_360": {
      publisher.gamAccountName = "Ad Manager 360";
      publisher.gamLogoType = GAM_LOGO_TYPE.AD_MANAGER_360;
      publisher.hasBillingSection = false;
      break;
    }
  }

  return publisher;
}

export default PublisherDetailsModal;
