import React from "react";
import { Query, Mutation } from "react-apollo";

import CommonStyle from "common/styles/common.css";

import YbLoading from "common/components/yb-loading";

import YbNetworkCandidates from "../components/yb-network-candidates";

import OAuth from "../../integration/components/oauth";
import {
  mutation as ADD_GAM_ACCOUNT,
  OAuthConfig,
} from "../../integration/query/gam-account";

class YbAddNetworkBtn extends React.Component {
  constructor(props) {
    super(props);

    this.openModal = this.openModal.bind(this);

    this.state = {
      showModal: false,
    };
  }

  openModal() {
    this.setState({
      showModal: true,
    });
  }

  setCandidatesAmount() {}

  closeCandidates(refetch, unused, data) {
    var callback = this.props.callback;

    if (callback) {
      setTimeout(() => {
        this.setState({
          showModal: false,
        });

        callback(data);
      }, 0);
    }
  }

  render() {
    var selectedAccount = this.props.account;
    var networks = this.props.networks;
    var showModal = this.state.showModal;
    var btnClass = this.props.btnClass || "";

    var refetch = () => {};

    return (
      <span>
        <span
          className={`${CommonStyle.button} ${btnClass}`}
          onClick={this.openModal}
        >
          <i className="fa fa-plus" /> {i18n`NEW_NETWORK`}
        </span>
        <YbNetworkCandidates
          inModal={showModal}
          loaded={this.setCandidatesAmount.bind(this)}
          callback={this.closeCandidates.bind(this, refetch)}
          permissionDeniedCallback={this.props.permissionDeniedCallback}
          account={selectedAccount}
          networks={networks}
          refetch={refetch}
          publisherId={this.props.publisherId}
        />
      </span>
    );
  }
}

module.exports = YbAddNetworkBtn;
