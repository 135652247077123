import gql from "graphql-tag";

export const GET_CANDIDATES = gql`
  query GET_CANDIDATES($filter: extGamNetworksFilter!, $pubId: Int) {
    extGamNetworks(filter: $filter, assumePubId: $pubId) {
      name
      code
    }
    gamNetworks(assumePubId: $pubId) {
      edges {
        node {
          id
          code
          status
        }
      }
    }
  }
`;

export const GET_ACCOUNTS = gql`
  query GET_ACCOUNTS($pubId: Int) {
    gamAccounts(first: 1000, assumePubId: $pubId) {
      edges {
        node {
          accountId
          name
          status
          problems
          publisher {
            id
            name
            publisherId
          }
          gamNetworks {
            edges {
              node {
                id
                networkId
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_INVENTORY = gql`
  query getNetworks($filter: gamAccountsFilter!, $pubId: Int) {
    gamAccounts(filter: $filter, assumePubId: $pubId) {
      edges {
        node {
          id
          accountId
          gamNetworks {
            edges {
              node {
                id
                networkId
                name
                code
                resources
                currency
                status
                problems
                yieldSets(first: 1000) {
                  edges {
                    node {
                      name
                      id
                      yieldSetId
                      status
                      problems
                      adxAccount {
                        id
                        adxAccountId
                        clientCode
                        name
                      }
                      adsenseAccount {
                        id
                        accountId
                        clientCode
                        name
                        adsenseAdUnits(first: 1000) {
                          edges {
                            node {
                              id
                              adUnitId
                              type
                              adSize {
                                sizeId
                                width
                                height
                              }
                            }
                          }
                        }
                      }
                      gamPlacements {
                        edges {
                          node {
                            name
                            id
                            placementId
                            extGamPlacementId
                            platformType
                            unitType
                            adxFloorPrice
                            adSenseFloorPrice
                            status
                            problems
                            adSizes {
                              sizeId
                              width
                              height
                            }
                          }
                        }
                      }
                      gamAdUnits(first: 10000) {
                        edges {
                          node {
                            id
                            name
                            adUnitId
                            extGamAdUnitId
                            extGamShadowAdUnitId
                            code
                            platformType
                            unitType
                            adxFloorPrice
                            adSenseFloorPrice
                            status
                            problems
                            parentPath
                            adSizes {
                              sizeId
                              width
                              height
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_NETWORKS = gql`
  query getNetworks($filter: gamNetworksFilter!, $pubId: Int) {
    gamNetworks(first: 1000, filter: $filter, assumePubId: $pubId) {
      edges {
        node {
          id
          networkId
          name
          code
          resources
          currency
          status
          problems
        }
      }
    }
  }
`;

export const GET_YIELDSETS = gql`
  query getYieldSets($filter: yieldSetsFilter!, $pubId: Int) {
    yieldSets(first: 1000, filter: $filter, assumePubId: $pubId) {
      edges {
        node {
          id
          name
          yieldSetId
          adxAccount {
            id
            name
            adxAccountId
          }
          adsenseAccount {
            id
            name
            accountId
          }
          gamNetwork {
            id
            name
            networkId
          }
        }
      }
    }
  }
`;

export const NETWORK_PROBLEMS = gql`
  query networkProblems(
    $adxFilter: adxAccountsFilter!
    $adsenseFilter: adsenseAccountsFilter!
    $pubId: Int
  ) {
    adsenseAccounts(first: 1000, filter: $adsenseFilter, assumePubId: $pubId) {
      edges {
        node {
          id
          accountId
          problems
        }
      }
    }

    adxAccounts(first: 1000, filter: $adxFilter, assumePubId: $pubId) {
      edges {
        node {
          id
          adxAccountId
          problems
        }
      }
    }
  }
`;

export const BOOST_NETWORK = gql`
  mutation BoostNetwork($input: createGamNetworkInput!, $pubId: Int) {
    createGamNetwork(input: $input, assumePubId: $pubId) {
      gamNetwork {
        networkId
      }
    }
  }
`;

export const TOGGLE_INVENTORY = gql`
  mutation ToggleAdUnitStatus(
    $adUnitInput: setGamAdUnitStatusInput!
    $placementInput: setGamPlacementStatusInput!
    $pubId: Int
  ) {
    setGamAdUnitStatus(input: $adUnitInput, assumePubId: $pubId) {
      result
    }
    setGamPlacementStatus(input: $placementInput, assumePubId: $pubId) {
      updatedGamPlacements {
        id
      }
    }
  }
`;

export const TOGGLE_AD_UNITS = gql`
  mutation TOGGLE_AD_UNITS(
    $adUnitInput: setGamAdUnitStatusInput!
    $pubId: Int
  ) {
    setGamAdUnitStatus(input: $adUnitInput, assumePubId: $pubId) {
      result
    }
  }
`;

export const SYNC_AD_UNIT = gql`
  mutation SyncAdUnits($input: syncGamNetworkInput!, $pubId: Int) {
    syncGamNetwork(input: $input, assumePubId: $pubId) {
      gamNetwork {
        id
        networkId
      }
    }
  }
`;

export const AUTHORIZE_GAM_ACCOUNT = gql`
  mutation AUTHORIZE_GAM_ACCOUNT(
    $input: authorizeGamAccountInput!
    $pubId: Int
  ) {
    authorizeGamAccount(input: $input, assumePubId: $pubId) {
      gamAccount {
        id
        accountId
      }
    }
  }
`;

export const GET_AD_UNITS = gql`
  query GET_AD_UNITS($pubId: Int) {
    gamAdUnits(first: 1000, assumePubId: $pubId) {
      edges {
        node {
          id
          name
          status
          extGamAdUnitId
          adUnitId
        }
      }
    }
  }
`;

export const GET_NETWORK_STATUS = gql`
  query GET_NETWORK_STATUS($filter: gamNetworksFilter!, $pubId: Int) {
    gamNetworks(first: 1000, filter: $filter, assumePubId: $pubId) {
      edges {
        node {
          id
          networkId
          name
          status
        }
      }
    }
  }
`;

export const VERIFY_GAM_NETWORK = gql`
  mutation VERIFY_GAM_NETWORK($input: verifyGamNetworkInput!, $pubId: Int) {
    verifyGamNetwork(input: $input, assumePubId: $pubId) {
      gamNetwork {
        id
        networkId
      }
    }
  }
`;
