import React from "react";
import { DATE_RANGE_TYPE } from "../helpers/date-helper";
import FilterInventory from "./filter-inventory";
import FilterDateRange from "./filter-date-range";
import FilterCurrencySelector from "./filter-currency-selector";

class FilterHeader extends React.Component {
  constructor(props) {
    super(props);

    const dateRangeType = DATE_RANGE_TYPE.THIS_MONTH;
    const { startDate, endDate } = props;

    this.state = {
      dateRangeType,
      startDate,
      endDate,
    };
  }

  render() {
    const {
      networks,
      selectedNetworkId,
      handleInventoryFilterChanged,
      handleDateRangeFilterChanged,
      yieldSets,
      selectedYieldSetId,
      selectedCurrency,
      currencies,
      handleCurrencyChanged,
      startDate,
      endDate,
    } = this.props;

    return (
      <React.Fragment>
        <div className="bg-light shadow-sm">
          <div className="d-flex justify-content-between" style={styles.header}>
            <div className="d-flex">
              <FilterInventory
                networks={networks}
                yieldSets={yieldSets}
                selectedNetworkId={selectedNetworkId}
                selectedYieldSetId={selectedYieldSetId}
                handleInventoryFilterChanged={handleInventoryFilterChanged}
              ></FilterInventory>

              <div className="ml-2">
                <FilterDateRange
                  startDate={startDate}
                  endDate={endDate}
                  handleDateRangeFilterChanged={handleDateRangeFilterChanged}
                ></FilterDateRange>
              </div>
            </div>

            <div>
              <FilterCurrencySelector
                currencies={currencies}
                selectedCurrency={selectedCurrency}
                handleCurrencyChanged={handleCurrencyChanged}
              ></FilterCurrencySelector>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const styles = {
  header: {
    padding: "8px 24px",
    maxWidth: "1280px",
    margin: "auto",
  },
  filterBtn: {
    border: "1px solid lightgray",
  },
  currencySelector: {
    width: "120px",
  },
};

export default FilterHeader;
