import React from "react";
import { Card, Alert } from "react-bootstrap";
import cogoToast from "cogo-toast";

import {
  GOOGLE_ACCOUNT_TYPE,
  GAM_LOGO_TYPE,
  API_ENDPOINT
} from "../../common/constants/common";

import PublisherBasicInfo from "./publisher-basic-info";
import PublisherGamProfile from "./publisher-gam-profile";
import PublisherContactInfo from "./publisher-contact-info";

function transformPublisherData(publisher) {
  let publisherData = publisher;
  switch (publisher.googleAccountType) {
    case GOOGLE_ACCOUNT_TYPE.AD_MANAGER: {
      publisher.gamAccountName = "Basic Ad Manager";
      publisherData.gamLogoType = GAM_LOGO_TYPE.AD_MANAGER;
      publisherData.hasBillingSection = false;
      break;
    }

    case GOOGLE_ACCOUNT_TYPE.INVOICED_AD_MANAGER: {
      publisher.gamAccountName = "Invoiced Ad Manager";
      publisherData.gamLogoType = GAM_LOGO_TYPE.AD_MANAGER;
      publisherData.hasBillingSection = true;
      break;
    }

    case GOOGLE_ACCOUNT_TYPE.AD_MANAGER_360: {
      publisher.gamAccountName = "Ad Manager 360";
      publisherData.gamLogoType = GAM_LOGO_TYPE.AD_MANAGER_360;
      publisherData.hasBillingSection = false;
      break;
    }
  }
  return publisherData;
}

function RegisterConfirmation(props) {
  const data = props.inviteData;
  const reseller = data.reseller;
  let publisher = transformPublisherData(data.publisher);

  async function handleSubmit(values) {
    const params = {
      rId: parseInt(reseller.resellerId),
      pId: parseInt(publisher.publisherId),
      h: data.hash,
      firstName: values.firstName,
      lastName: values.lastName,
      contactEmail: values.contactEmail
    };

    try {
      const response = await confirmRegistration(params);
      console.log("confirm validation", response);
      if (response.ok) {
        // Set user as registered in main router
        props.onSignup();
      } else {
        throw new Error("Failed to confirm register.");
      }
    } catch (err) {
      console.log(err);
      if (err.message === "Failed to fetch") {
        cogoToast.error(
          `Your session is expired, please go back and sign in again with your google account.`
        );
      } else {
        cogoToast.error(`Something's wrong with our server: ${err.message}`);
      }
    }
  }

  return (
    <React.Fragment>
      <h1 className="text-center mb-4">
        Welcome <b>{publisher.name}</b>
      </h1>
      <h3 className="text-justify mb-4">
        Reseller <b style={{ color: "#0348b7" }}>{reseller.name}</b> has filled
        in your publisher profile below, please double check if the info is
        correct then confirm the registration to get started!
      </h3>

      <div>
        <div>
          <PublisherBasicInfo publisher={publisher} />
          <PublisherGamProfile publisher={publisher} />
        </div>

        <div className="mt-2 mb-2">
          <Card className="shadow-sm p-2">
            <Card.Body className="mt-0 mb-0 p-2">
              <Alert variant="warning" className="mb-0 p-4 font-weight-bold">
                <i className="fa fa-exclamation-circle fa-fw" /> Please contact
                your reseller if the above info is incorrect and make sure your
                reseller corrects the info before you continue the registration.
              </Alert>
            </Card.Body>
          </Card>
        </div>

        <div>
          <PublisherContactInfo
            handleSubmit={handleSubmit}
            resellerName={reseller.name}
          />
        </div>
      </div>
    </React.Fragment>
  );
}

async function confirmRegistration(params) {
  const url = `${API_ENDPOINT}/pubRegister/v1/confirmRegister`;
  const options = {
    method: "post",
    credentials: "include",
    body: JSON.stringify(params)
  };

  const response = await fetch(url, options);
  return response;
}

export default RegisterConfirmation;
