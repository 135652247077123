import React from "react";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import FilterInventoryPopover from "./filter-inventory-popover";

class FilterInventory extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
    };

    this.handleClosePopover = this.handleClosePopover.bind(this);
    this.onOpenPopover = this.onOpenPopover.bind(this);
  }

  onOpenPopover() {
    this.setState({ show: !this.state.show });
  }

  handleClosePopover() {
    this.setState({ show: false });
  }

  render() {
    const {
      networks,
      selectedNetworkId,
      selectedYieldSetId,
      handleInventoryFilterChanged,
      yieldSets,
    } = this.props;

    let selectorText = "";
    if (selectedNetworkId === -1) {
      selectorText = <b>All networks</b>;
    } else if (selectedYieldSetId !== -1 && selectedNetworkId !== -1) {
      const nName = _.find(networks, { id: selectedNetworkId }).name;
      const yName = _.find(yieldSets, { id: selectedYieldSetId }).name;
      selectorText = (
        <span>
          Network: <b style={{ marginRight: "4px" }}>{nName}</b> Yield Set:{" "}
          <b>{yName}</b>
        </span>
      );
    } else if (selectedYieldSetId === -1 && selectedNetworkId !== -1) {
      const nName = _.find(networks, { id: selectedNetworkId }).name;
      selectorText = (
        <span>
          Network: <b>{nName}</b>
        </span>
      );
    }

    return (
      <React.Fragment>
        <OverlayTrigger
          rootClose={true} // close when clicked outside overlay
          show={this.state.show}
          onToggle={this.onOpenPopover}
          trigger="click"
          placement="bottom-start"
          overlay={
            <Popover
              id="inventory-selector-popover"
              // title="Select Network"
              className="shadow-lg p-2"
              style={{
                maxWidth: "600px",
                width: "600px",
                backgroundColor: "#f7f7f7",
              }}
            >
              <FilterInventoryPopover
                networks={networks}
                selectedNetworkId={selectedNetworkId}
                selectedYieldSetId={selectedYieldSetId}
                handleInventoryFilterChanged={handleInventoryFilterChanged}
                yieldSets={yieldSets}
                handleClosePopover={this.handleClosePopover}
              ></FilterInventoryPopover>
            </Popover>
          }
        >
          <Button
            variant="light"
            style={styles.filterBtn}
            className="text-dark px-3 d-flex align-items-center"
          >
            <div className="text-truncate" style={styles.filterText}>
              {selectorText}
            </div>
            <span className="fa fa-angle-down ml-2"></span>
          </Button>
        </OverlayTrigger>
      </React.Fragment>
    );
  }
}

const styles = {
  header: {
    padding: "12px 84px",
  },
  filterBtn: {
    border: "1px solid lightgray",
  },
  filterText: {
    maxWidth: "520px",
  },
  currencySelector: {
    width: "120px",
  },
};

export default FilterInventory;
