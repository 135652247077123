import gql from "graphql-tag";

export const UPDATE_NETWORK = gql`
  mutation UPDATE_NETWORK(
    $input: setGamNetworkAdServingCostInput!
    $pubId: Int
  ) {
    setGamNetworkAdServingCost(input: $input, assumePubId: $pubId) {
      gamNetwork {
        id
      }
    }
  }
`;

export const QUERY_NETWORK = gql`
  query QUERY_NETWORK($pubId: Int) {
    gamNetworks(assumePubId: $pubId) {
      edges {
        node {
          id
          networkId
          name
          adServingCost {
            useDefault
            costCpm
            freeQuota
          }
        }
      }
    }
  }
`;
