import React from "react";
import FadeInWrapper from "./fade-in-wrapper";
import NumberFormat from "./number-format";

import { formatMoneyWithCurrency } from "../helpers/reports-helper";
import RevenueDistributionSection from "./revenue-distribution-section";

import RightArrowLong from "../../common/images/right-arrow-long.svg";
import InfoDescriptionTooltip from "./info-desc-tooltip";
import PerceivedLiftMetric from "./perceived-lift-metric";
import SummaryRateGaugeChart from "./summary-rate-gauge-chart";
import ManagedLiftMetric from "./managed-lift-metric";

class SummarySection extends React.PureComponent {
  render() {
    const {
      summaryData,
      distributionData,
      selectedNetworkId,
      selectedYieldSetId,
      startDate,
      endDate,
      selectedCurrency,
    } = this.props;

    return (
      <React.Fragment>
        <div className="mt-4" style={styles.sectionTitle}>
          Summary
        </div>

        <div className="d-flex">
          <div style={{ width: "65%", marginRight: "16px" }}>
            <div className="border rounded p-3 mb-3">
              <div className="d-flex align-items-center justify-content-center">
                <div style={styles.statBlock}>
                  <div style={styles.statTitle}>
                    Net Increased Revenue
                    <InfoDescriptionTooltip
                      metricKey="NET_INCREASED_GOOGLE_REV"
                      place="top"
                    ></InfoDescriptionTooltip>
                  </div>
                  <div style={styles.statText}>
                    <FadeInWrapper key={summaryData.netIncreasedRevenue}>
                      {formatMoneyWithCurrency({
                        currency: selectedCurrency,
                        value: summaryData.netIncreasedRevenue,
                      })}
                    </FadeInWrapper>
                  </div>
                </div>
                <div className="border-left ml-3 pl-3 mb-2 align-self-end">
                  <PerceivedLiftMetric
                    perceivedLift={summaryData.perceivedLift}
                  ></PerceivedLiftMetric>

                  <div
                    style={{
                      fontSize: "12px",
                      color: "#718096",
                      fontWeight: "400",
                    }}
                  >
                    Perceived Lift
                    <InfoDescriptionTooltip
                      metricKey="PERCEIVED_REVENUE_UPLIFT"
                      place="top"
                    ></InfoDescriptionTooltip>
                  </div>
                </div>
                <div className="border-left ml-3 pl-3 mb-2 align-self-end">
                  <ManagedLiftMetric
                    managedLift={summaryData.grossRevenueLift}
                  ></ManagedLiftMetric>

                  <div
                    style={{
                      fontSize: "12px",
                      color: "#718096",
                      fontWeight: "400",
                    }}
                  >
                    Managed Lift
                    <InfoDescriptionTooltip
                      metricKey="MANAGED_UPLIFT"
                      place="top"
                    ></InfoDescriptionTooltip>
                  </div>
                </div>
              </div>
            </div>
            <div className="border rounded p-3">
              <div style={styles.statBlock}>
                <div style={styles.statTitle} className="mb-3">
                  Request Funnel
                </div>
                <div style={styles.gridContainer}>
                  <div style={styles.gridCol} className="d-flex flex-column">
                    <ReqFunnelCol
                      metricKey="ONBOARDED_REQ"
                      title="Onboarded"
                      value={summaryData.onboardedRequests}
                    ></ReqFunnelCol>
                  </div>

                  <div style={styles.gridCol}>
                    <RightArrow></RightArrow>
                  </div>

                  <div style={styles.gridCol} className="d-flex flex-column">
                    <ReqFunnelCol
                      metricKey="ELIGIBLE_REQ"
                      title="Eligible"
                      value={summaryData.remnentRequests}
                    ></ReqFunnelCol>
                  </div>

                  <div style={styles.gridCol}>
                    <RightArrow></RightArrow>
                  </div>

                  <div style={styles.gridCol} className="d-flex flex-column">
                    <ReqFunnelCol
                      metricKey="MANAGED_REQ"
                      title="Managed"
                      value={summaryData.boostingRequests}
                    ></ReqFunnelCol>
                  </div>

                  <div style={styles.gridCol}></div>
                  <div style={styles.gridCol} className="d-flex flex-column">
                    <ReqFunnelColRate
                      metricKey="REQ_ELIGIBLE_RATE"
                      title="Eligible Rate"
                      value={summaryData.remnentRate}
                    ></ReqFunnelColRate>
                  </div>

                  <div style={styles.gridCol}></div>
                  <div style={styles.gridCol} className="d-flex flex-column">
                    <ReqFunnelColRate
                      metricKey="REQ_MANAGED_RATE"
                      title="Managed Rate"
                      value={summaryData.boostingRate}
                    ></ReqFunnelColRate>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{ width: "35%" }}>
            <div className="border rounded p-3" style={{ height: "100%" }}>
              <RevenueDistributionSection
                selectedNetworkId={selectedNetworkId}
                selectedYieldSetId={selectedYieldSetId}
                startDate={startDate}
                endDate={endDate}
                distributionData={distributionData}
                selectedCurrency={selectedCurrency}
              ></RevenueDistributionSection>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function ReqFunnelColRate(props) {
  const { title, value, metricKey } = props;
  return (
    <React.Fragment>
      <div style={styles.funnelStatSm}>
        <FadeInWrapper key={value}>
          <SummaryRateGaugeChart value={value}></SummaryRateGaugeChart>
        </FadeInWrapper>
      </div>
      <div style={styles.funnelTitleSm}>
        {title}
        <InfoDescriptionTooltip
          metricKey={metricKey}
          place="top"
        ></InfoDescriptionTooltip>
      </div>
    </React.Fragment>
  );
}

function ReqFunnelCol(props) {
  const { title, value, metricKey } = props;
  return (
    <React.Fragment>
      <div style={styles.funnelTitle}>
        {title}
        <InfoDescriptionTooltip
          metricKey={metricKey}
          place="top"
        ></InfoDescriptionTooltip>
      </div>
      <div style={styles.funnelStat}>
        <FadeInWrapper key={value}>
          <NumberFormat value={value} format="0,0.00a"></NumberFormat>
        </FadeInWrapper>
      </div>
    </React.Fragment>
  );
}

function RightArrow() {
  return <img src={RightArrowLong} />;
  // return <span className="fa fa-arrow-right" style={styles.arrow}></span>;
}

const styles = {
  gridContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(5,minmax(0,1fr))",
    gridGap: "0.5rem",
    gap: "0.5rem",
    width: "100%",
  },
  gridCol: {
    gridColumn: "span 1/span 1",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  funnelTitle: {
    color: "#374151",
    fontSize: "14px",
    fontWeight: "500",
  },
  funnelStat: {
    fontSize: "20px",
    color: "#2d3748",
  },
  funnelTitleSm: {
    fontSize: "12px",
    color: "#4B5563",
  },
  funnelStatSm: {
    fontSize: "16px",
    color: "#374151",
  },
  arrow: {
    color: "#6B7280",
  },
  sectionTitle: {
    fontSize: "28px",
    marginBottom: "16px",
    color: "#374151",
    fontWeight: "600",
  },
  statTitle: {
    color: "#556377",
    fontWeight: "500",
  },
  statText: {
    fontSize: "42px",
    fontWeight: "600",
  },
  statBlock: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },
};

export default SummarySection;
