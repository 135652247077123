import React from "react";
import _ from "lodash";

// Import Highcharts
import Highcharts from "highcharts";
import drilldown from "highcharts/modules/drilldown.js";
import HighchartsReact from "highcharts-react-official";

import { formatMoneyWithCurrency } from "../helpers/reports-helper";
import InfoDescriptionTooltip from "./info-desc-tooltip";

drilldown(Highcharts);

Highcharts.setOptions({
  lang: {
    decimalPoint: ".",
    thousandsSep: ",",
  },
  credits: {
    enabled: false,
  },
});

class RevenueDistributionDrilldownPieChart extends React.Component {
  constructor(props) {
    super(props);

    const {
      reports,
      metricKey,
      selectedCurrency,
      selectedNetworkId,
      selectedYieldSetId,
    } = props;

    this.state = {
      metricKey: props.metricKey,
      options: _createChartOptions({
        reports,
        metricKey,
        selectedCurrency,
        selectedNetworkId,
        selectedYieldSetId,
      }),
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.startDate !== this.props.startDate ||
      prevProps.endDate !== this.props.endDate ||
      prevProps.selectedNetworkId !== this.props.selectedNetworkId ||
      prevProps.selectedYieldSetId !== this.props.selectedYieldSetId ||
      prevProps.selectedCurrency !== this.props.selectedCurrency
    ) {
      const {
        reports,
        metricKey,
        selectedCurrency,
        selectedNetworkId,
        selectedYieldSetId,
      } = this.props;

      this.setState({
        options: _createChartOptions({
          reports,
          metricKey,
          selectedCurrency,
          selectedNetworkId,
          selectedYieldSetId,
        }),
      });
    }
  }

  render() {
    const { options } = this.state;
    const { selectedNetworkId, selectedYieldSetId } = this.props;

    let isBreakdownByYieldSet = false;
    if (selectedNetworkId !== -1 || selectedYieldSetId !== -1) {
      isBreakdownByYieldSet = true;
    }

    return (
      <div className="text-center mt-2">
        <div style={styles.statTitle}>
          {isBreakdownByYieldSet ? (
            <div>
              Yield Sets
              <InfoDescriptionTooltip
                metricKey="YIELD_SET"
                place="top"
              ></InfoDescriptionTooltip>
            </div>
          ) : (
            <div>Networks</div>
          )}
        </div>
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
          allowChartUpdate={true}
          immutable={true} // this fixes chart not updating after selecting ys then selecting all ys
        />
      </div>
    );
  }
}

function _createChartOptions({
  reports,
  metricKey,
  selectedCurrency,
  selectedNetworkId,
  selectedYieldSetId,
}) {
  let networkTotal = _.sumBy(reports.networks, metricKey);
  let ysTotal = _.sumBy(reports.yieldSets, metricKey);

  let isDrilldown = true;
  if (selectedNetworkId !== -1 || selectedYieldSetId !== -1) {
    isDrilldown = false;
  }

  let selectedNetworkPerc, selectedNetworkName;

  const networkData = _.map(reports.networks, (n) => {
    const d = n[metricKey];
    const p = _.round((d / networkTotal) * 100, 2);

    const valueFormatted = formatMoneyWithCurrency({
      currency: selectedCurrency,
      value: d,
    });

    if (n.id === selectedNetworkId) {
      selectedNetworkPerc = p;
      selectedNetworkName = n.name;
    }

    const r = {
      name: n.name,
      y: d,
      valueFormatted,
      perc: p,
    };

    return r;
  });

  const ysDataGroupByNetwork = _.groupBy(reports.yieldSets, "gamNetworkId");
  let ysSeriesData = [];
  _.forEach(ysDataGroupByNetwork, (yieldSets, networkId) => {
    const ysData = {
      name: networkId,
      id: networkId,
      data: _.map(yieldSets, (ys) => {
        const d = ys[metricKey];
        const p = _.round((d / ysTotal) * 100, 2);

        const valueFormatted = formatMoneyWithCurrency({
          currency: selectedCurrency,
          value: d,
        });

        let isSelectedYs = selectedYieldSetId == ys.id;

        const r = {
          name: ys.name,
          y: d,
          valueFormatted,
          perc: p,
        };

        if (selectedYieldSetId !== -1) {
          r.color = isSelectedYs ? "#95CEFF" : "lightgray";
          r.dataLabels = {
            enabled: isSelectedYs,
            color: isSelectedYs ? "#000" : "lightgray",
          };

          r.sliced = isSelectedYs;
          r.selected = isSelectedYs;
        }

        return r;
      }),
    };

    ysSeriesData.push(ysData);
  });

  const metricKeyName = _.startCase(metricKey);

  const options = {
    title: {
      text: "",
    },
    chart: {
      type: "pie",
      height: 242,
      backgroundColor: "rgba(0,0,0,0)",
    },

    plotOptions: {
      pie: {
        size: "100%",
        // showInLegend: true,
        dataLabels: {
          enabled: true,
          distance: 4,
          format: "<b>{point.name}</b> ({point.percentage:.2f}%)",
        },
        states: {
          hover: {
            halo: {
              opacity: 0.1,
            },
          },
        },
      },
    },

    // legend: {
    //   layout: "vertical",
    //   enabled: true,
    //   floating: true,
    //   align: "right",
    //   verticalAlign: "middle",
    //   labelFormat: "{name} {percentage:.2f}%",
    // },

    tooltip: {
      useHTML: true,
      formatter: function () {
        return `
          <table>
            <thead>
              <tr>
                <th style="font-size: 14px; font-weight: bold;" colspan="2">
                  ${this.point.name}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  ${metricKeyName}
                </td>
                <td style="font-weight: bold;">
                  ${this.point.valueFormatted}
                </td>
              </tr>
              <tr>
                <td>
                  % of total
                </td>
                <td style="font-weight: bold;">
                  ${this.point.perc}%
                </td>
              </tr>
            </tbody>
          </table>
        `;
      },
    },

    series: [
      {
        name: isDrilldown ? "Networks" : "Yield Sets",
        // colorByPoint: true,
        data: isDrilldown
          ? networkData
          : _.get(
              _.find(ysSeriesData, { id: selectedNetworkId.toString() }),
              "data",
              []
            ),
      },
    ],
  };

  return options;
}

const styles = {
  statTitle: {
    color: "#556377",
    fontWeight: "500",
  },
};

export default RevenueDistributionDrilldownPieChart;
