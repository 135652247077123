export const API_ENDPOINT = process.env.INTOWOW_GRAPHQL_ENDPOINT;

export const USER_ROLE = {
  UNRECOGNIZED_USER: "UNRECOGNIZED_USER",
  RESELLER_ADMIN: "RESELLER_ADMIN",
  MANAGED_PUBLISHER_ADMIN: "MANAGED_PUBLISHER_ADMIN",
  DIRECT_PUBLISHER_ADMIN: "DIRECT_PUBLISHER_ADMIN"
};

export const TRIAL_STATUS = {
  TRIAL: "TRIAL",
  TRIAL_EXPIRED: "TRIAL_EXPIRED"
};

export const SUBSCRIPTION_STATUS = {
  SUBSCRIBED: "SUBSCRIBED",
  NOT_SUBSCRIBED: "NOT_SUBSCRIBED"
};

export const GOOGLE_ACCOUNT_TYPE = {
  AD_MANAGER: 0,
  INVOICED_AD_MANAGER: 1,
  AD_MANAGER_360: 2
};

export const GAM_LOGO_TYPE = {
  AD_MANAGER: "AD_MANAGER",
  AD_MANAGER_360: "AD_MANAGER_360"
};
