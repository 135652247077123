import React from "react";
import { Query } from "react-apollo";

import YbLoadingView from "common/components/yb-loading-view";

import RootLayerWrapper from "../../_newapp/onboarding/components/root-layer-wrapper";

import { GET_ONBOARDED_AD_UNITS } from "../../_newapp/onboarding/query/queries";

class YbWizardStep4 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      publisherId: props.publisherId,

      selectedItems: [],
    };
  }

  render() {
    var stepData = this.props.stepData;
    var selectedYieldSet = stepData.selectedYieldSet;

    stepData.getNextStepBtn = this.getNextStepBtn;

    if (!selectedYieldSet) {
      return <span />;
    }

    const yieldSetId = selectedYieldSet.yieldSetId;
    const gamNetworkId = selectedYieldSet.gamNetwork.networkId;
    const publisherId = this.state.publisherId;

    return (
      <React.Fragment>
        <Query query={GET_ONBOARDED_AD_UNITS} fetchPolicy={"network-only"}>
          {({ loading, error, data }) => {
            if (loading) {
              return <YbLoadingView />;
            }

            if (error) {
              console.log(error);
              return (
                <div style={{ color: "red" }}>
                  Error Loading Onboarded Ad Units Information
                  <div style={{ fontSize: "14px" }}>
                    {error.message && JSON.stringify(error.message, null, 4)}
                  </div>
                </div>
              );
            }

            if (data) {
              const onboardedExtUnitIds = _(_.get(data, "units.edges", []))
                .map((item) => {
                  return _.get(item, "node.extId");
                })
                .compact()
                .value();

              return (
                // textAlign left to override Wizard's overall style
                <div style={{ textAlign: "left" }}>
                  <RootLayerWrapper
                    yieldSetId={yieldSetId}
                    gamNetworkId={gamNetworkId}
                    publisherId={publisherId}
                    onboardedExtUnitIds={onboardedExtUnitIds}
                    wizard={{
                      renderPrevStepBtn: this.props.renderPrevStepBtn,
                      renderExitBtn: this.props.renderExitBtn,
                      nextStepFnForStep4: this.props.nextStepFnForStep4,
                    }}
                  ></RootLayerWrapper>
                </div>
              );
            }
          }}
        </Query>
      </React.Fragment>
    );
  }
}

module.exports = YbWizardStep4;
