import React from "react";
import { withRouter, Route, Switch, Redirect } from "react-router-dom";
import qs from "querystring";

import PATHNAME from "../constants/routes-pathnames";

// reseller only
import Publishers from "../../publishers/states/publishers";
// import ResellerDashboard from "../../dashboards/states/reseller-dashboard";
import ResellerBilling from "../../billing/states/index";

// shared
import Terms from "../../../terms";
import Users from "../../../user/states/list";
import SummaryOverview from "../../../summary/states/overview";
// import AdSizeOptimization from "../../../optimization/states/ad-size";
import Wizard from "../../../wizard/states/wizard";
import DashboardOverview from "../../../dashboard/states/overview";
import SupportCenter from "../../../support/states/center";

import YieldSetForm from "../../../yield-sets/states/yield-set";
import AdUnitForm from "../../../ad-units/states/ad-unit";
import AdUnitFormV2 from "../../../ad-units/states/ad-unit-v2";
// import AdUnitFormV2 from "../../../ad-units/states/ad-unit-v3";
// import OnboardingAdUnits from "../../../ad-units/states/ad-unit-v4"; // night before version
import OnboardingAdUnits from "../../onboarding/states/onboarding-ad-units";

import { OAuthCallback, Bind } from "../../../integration";
import NetworkAccount from "../../../integration/states/network-accounts";
import NoMatch from "../../../no-match";

// direct publisher only
import SubscriptionAndBillingBase from "../../../subscription-and-billing/states/index";
// import SubscriptionAndBillingOverview from "./../../../subscription-and-billing/states/overview";
import BillingSetting from "../../../subscription-and-billing/states/billing-setting";
import Subscribe from "../../../subscription-and-billing/states/subscribe";
import BaseOverview from "../states/base-overview";
import HomeDashboard from "../../home-dashboard/states/home-dashboard-v2";
// import HomeDashboard from "../../home-dashboard/states/home-dashboard";

// TODO: settings
import ResellerSettings from "../../settings/states/reseller-settings";
// import ManagedPublisherSettings from "../../settings/states/managed-publisher-settings";
// import DirectPublisherSettings from "./../../settings/states/direct-publisher-settings";

class BaseRouter extends React.Component {
  constructor(props) {
    super(props);

    const defaultPath = "/overview";

    this.state = {
      defaultPath,
    };
  }

  OAuthState() {
    const searchStr = window.location.search;
    if (searchStr) {
      const search = qs.parse(searchStr.substr(1));
      return qs.parse(decodeURIComponent(search.state));
    }

    return {};
  }

  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route path={PATHNAME.terms} component={Terms} />
          <Route path={PATHNAME.home} component={HomeDashboard} />
          <Route path={PATHNAME.publishers} component={Publishers} />
          <Route
            path={PATHNAME.resellerDashboard}
            component={DashboardOverview}
          />
          <Route path={PATHNAME.resellerBilling} component={ResellerBilling} />
          <Route path={PATHNAME.settings} component={ResellerSettings} />
          <Route path={PATHNAME.inventorySummary} component={SummaryOverview} />
          {/* <Route
            path={PATHNAME.adSizeOptimization}
            component={AdSizeOptimization}
          /> */}
          <Route path={PATHNAME.onBoardWizard} component={Wizard} />
          <Route
            path={PATHNAME.dashboardOverview}
            component={DashboardOverview}
          />
          <Route path={PATHNAME.users} component={Users} />
          <Route path={PATHNAME.supportCenter} component={SupportCenter} />

          <Route
            path={PATHNAME.subscriptionAndBilling.overview}
            component={SubscriptionAndBillingBase}
          />
          <Route
            path={PATHNAME.subscriptionAndBilling.billingReport}
            component={SubscriptionAndBillingBase}
          />
          <Route
            path={PATHNAME.subscriptionAndBilling.paymentHistory}
            component={SubscriptionAndBillingBase}
          />
          <Route
            path={PATHNAME.subscriptionAndBilling.billingSetting}
            component={BillingSetting}
          />
          <Route
            path={PATHNAME.subscriptionAndBilling.subscribe}
            component={Subscribe}
          />

          <Route path={PATHNAME.yieldSet.create} component={YieldSetForm} />
          <Route path={PATHNAME.yieldSet.update} component={YieldSetForm} />
          <Route path={PATHNAME.adUnit.create} component={AdUnitFormV2} />
          <Route path={PATHNAME.adUnit.update} component={AdUnitForm} />
          <Route path={PATHNAME.placement.create} component={AdUnitForm} />
          <Route path={PATHNAME.placement.update} component={AdUnitForm} />
          <Route
            path={PATHNAME.inventoryCreate}
            component={OnboardingAdUnits}
          />
          <Route
            path={PATHNAME.networkAccounts}
            render={(props) => (
              <NetworkAccount
                networkId={parseInt(props.match.params.networkId)}
                {...props}
              />
            )}
          />

          <Route
            path={PATHNAME.integration}
            render={(props) => (
              <Bind redirect={this.state.defaultPath} count={5} {...props} />
            )}
          />

          {/* Reseller controlling publisher */}
          <Route
            path={PATHNAME.publisherBase.inventorySummary}
            component={SummaryOverview}
          />
          {/* <Route
            path={PATHNAME.publisherBase.adSizeOptimization}
            render={(props) => (
              <AdSizeOptimization
                publisherId={parseInt(props.match.params.publisherId)}
                {...props}
              />
            )}
          /> */}
          <Route
            path={PATHNAME.publisherBase.onBoardWizard}
            component={Wizard}
          />
          <Route
            path={`${PATHNAME.publisherBase.basePath}${PATHNAME.networkAccounts}`}
            render={(props) => (
              <NetworkAccount
                publisherId={parseInt(props.match.params.publisherId)}
                networkId={parseInt(props.match.params.networkId)}
                {...props}
              />
            )}
          />
          <Route
            path={`${PATHNAME.publisherBase.basePath}${PATHNAME.yieldSet.create}`}
            render={(props) => (
              <YieldSetForm
                publisherId={parseInt(props.match.params.publisherId)}
                {...props}
              />
            )}
          />
          <Route
            path={`${PATHNAME.publisherBase.basePath}${PATHNAME.yieldSet.update}`}
            render={(props) => (
              <YieldSetForm
                publisherId={parseInt(props.match.params.publisherId)}
                {...props}
              />
            )}
          />
          <Route
            path={`${PATHNAME.publisherBase.basePath}${PATHNAME.inventoryCreate}`}
            render={(props) => (
              <OnboardingAdUnits
                publisherId={parseInt(props.match.params.publisherId)}
                {...props}
              />
            )}
          />
          <Route
            path={`${PATHNAME.publisherBase.basePath}${PATHNAME.adUnit.create}`}
            render={(props) => (
              <OnboardingAdUnits
                publisherId={parseInt(props.match.params.publisherId)}
                {...props}
              />
            )}
          />
          <Route
            path={`${PATHNAME.publisherBase.basePath}${PATHNAME.adUnit.update}`}
            render={(props) => (
              <AdUnitForm
                publisherId={parseInt(props.match.params.publisherId)}
                {...props}
              />
            )}
          />
          <Route
            path={`${PATHNAME.publisherBase.basePath}${PATHNAME.placement.create}`}
            render={(props) => (
              <AdUnitForm
                publisherId={parseInt(props.match.params.publisherId)}
                {...props}
              />
            )}
          />
          <Route
            path={`${PATHNAME.publisherBase.basePath}${PATHNAME.placement.update}`}
            render={(props) => (
              <AdUnitForm
                publisherId={parseInt(props.match.params.publisherId)}
                {...props}
              />
            )}
          />
          <Route
            path={`${PATHNAME.publisherBase.basePath}${PATHNAME.integration}`}
            render={(props) => (
              <Bind
                publisherId={parseInt(props.match.params.publisherId)}
                redirect={this.state.defaultPath}
                count={5}
                {...props}
              />
            )}
          />

          <Route path="/overview" component={BaseOverview} />

          <Route
            path="/signup"
            render={(props) => {
              console.log("signup page redirecting...", props);
              return <Redirect push={false} to={this.state.defaultPath} />;
            }}
          />

          <Route
            path="/login"
            render={(props) => {
              console.log("Login page redirecting...", props);
              return <Redirect push={false} to={this.state.defaultPath} />;
            }}
          />

          <Route
            path="/invite"
            render={(props) => {
              console.log("invite page redirecting...", props);
              return <Redirect push={false} to={this.state.defaultPath} />;
            }}
          />

          <Route
            exact
            path="/"
            render={(props) => {
              console.log(
                "In Base Router, automactically routing to...",
                props
              );

              const oauthState = this.OAuthState();

              if (oauthState && oauthState.intowow_oauth) {
                console.log("OAuthCallback", oauthState);
                return <OAuthCallback oauthState={oauthState} />;
              }

              return <Redirect push={false} to={this.state.defaultPath} />;
            }}
          />

          <Route
            render={(props) => (
              <NoMatch {...props} link={this.state.defaultPath} />
            )}
          />
        </Switch>
      </React.Fragment>
    );
  }
}

export default withRouter(BaseRouter);
