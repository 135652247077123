import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  ListGroup,
  Button,
  Alert,
  Form
} from "react-bootstrap";
import cogoToast from "cogo-toast";
import {
  Formik,
  Form as FormikForm,
  Field,
  ErrorMessage,
  FieldArray
} from "formik";

import * as Yup from "yup";

const RegistrationFormSchema = Yup.object().shape({
  firstName: Yup.string().required("Please fill in your first name"),
  lastName: Yup.string().required("Please fill in your last name"),
  contactEmail: Yup.string()
    .email()
    .required("Please fill in your email")
});

function PublisherContactInfo(props) {
  const resellerName = props.resellerName;
  const handleSubmit = props.handleSubmit;

  const defaultParams = {
    firstName: "",
    lastName: "",
    contactEmail: ""
  };

  const [registerParams, setRegisterParams] = useState(defaultParams);

  function handleFormSubmit(values) {
    console.log("submit", values);
    setRegisterParams(values);

    handleSubmit(values);
  }

  return (
    <React.Fragment>
      <Card className="shadow-sm">
        <Card.Body className="p-4">
          <div className="text-muted mb-4" style={{ letterSpacing: "0.04em" }}>
            <b>PRIMARY CONTACT INFO</b>
            <hr />
          </div>
          <p className="mb-0 text-dark">
            This will be the person we will contact if there are any emergency
            problems regarding your inventory through our service.
          </p>
          <p className="mb-4 text-info">
            Don't worry, we will not use this data to send marketing emails or
            spam messages.
          </p>

          <Formik
            initialValues={registerParams}
            validationSchema={RegistrationFormSchema}
            onSubmit={handleFormSubmit}
            render={props => {
              const { errors, touched, values } = props;

              return (
                <FormikForm onSubmit={props.handleSubmit}>
                  <Form.Row>
                    <Form.Group as={Col} controlId="firstName">
                      <Form.Label
                        className="text-dark"
                        style={{
                          fontSize: "18px",
                          fontWeight: "bolder"
                        }}
                      >
                        First Name
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="firstName"
                        onChange={props.handleChange}
                        value={values.firstName}
                        isValid={touched.firstName && !errors.firstName}
                        isInvalid={errors.firstName && touched.firstName}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.firstName}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} controlId="lastName">
                      <Form.Label
                        className="text-dark"
                        style={{
                          fontSize: "18px",
                          fontWeight: "bolder"
                        }}
                      >
                        Last Name
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="lastName"
                        onChange={props.handleChange}
                        value={values.lastName}
                        isValid={touched.lastName && !errors.lastName}
                        isInvalid={errors.lastName && touched.lastName}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.lastName}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Form.Row>

                  <Form.Row>
                    <Form.Group as={Col} controlId="contactEmail">
                      <Form.Label
                        className="text-dark"
                        style={{
                          fontSize: "18px",
                          fontWeight: "bolder"
                        }}
                      >
                        Contact Email
                      </Form.Label>

                      <Form.Control
                        type="text"
                        name="contactEmail"
                        onChange={props.handleChange}
                        value={values.contactEmail}
                        isValid={touched.contactEmail && !errors.contactEmail}
                        isInvalid={errors.contactEmail && touched.contactEmail}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.contactEmail}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Form.Row>

                  <div className="mt-4 mb-3">
                    <div className="d-flex justify-content-center">
                      <Button
                        variant="primary"
                        type="submit"
                        className="ml-2"
                        style={{
                          paddingLeft: "24px",
                          paddingRight: "24px"
                        }}
                      >
                        Confirm and register
                      </Button>
                    </div>
                    <p className="text-secondary text-center">
                      <small>
                        Once you confirm, you are agreeing that{" "}
                        <b>{resellerName}</b> will be able to manage your
                        inventory.
                      </small>
                    </p>
                  </div>
                </FormikForm>
              );
            }}
          />
        </Card.Body>
      </Card>
    </React.Fragment>
  );
}

export default PublisherContactInfo;
