import React, { Fragment, useState, useEffect } from "react";
import { withApollo } from "react-apollo";

import { GET_ALL_COUNTRIES } from "../../common/query/countries";
import Select from "react-select";
import _ from "lodash";

function CountrySelector(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCountry, setCountry] = useState();
  const [data, setData] = useState({ selectOptions: [] });

  useEffect(() => {
    async function fetch() {
      setIsLoading(true);

      const countries = await queryCountries();

      let selectOptions = countries.map(country => {
        return {
          value: country.countryId,
          label: i18n(country.name)
        };
      });

      let defaultCountry = _.find(selectOptions, { value: props.geoCountryId });
      setCountry(defaultCountry);

      setData({ selectOptions });
      setIsLoading(false);
    }

    fetch();
  }, []);

  function queryCountries() {
    const { client } = props;
    return client.query({ query: GET_ALL_COUNTRIES }).then(res => {
      const items = _.get(res, "data.geoCountrys.edges"); // spelling is on purpose
      return items.map(item => {
        return {
          countryId: item.node.countryId,
          name: item.node.name
        };
      });
    });
  }

  function handleChange(selectedOption) {
    setCountry(selectedOption);
    props.handleChange(selectedOption.value);
  }

  return (
    <Fragment>
      <Select
        value={selectedCountry}
        onChange={handleChange}
        options={data.selectOptions}
        isLoading={isLoading}
        isSearchable={true}
        placeholder="Select your billing country"
        // TODO: make border red if invalid
        // styles={{
        //   control: (provided, state) => {
        //     return {
        //       ...provided,
        //       borderColor: "red"
        //     };
        //   }
        // }}
      />
    </Fragment>
  );
}

module.exports = withApollo(CountrySelector);
