import React from "react";
import _ from "lodash";
import { Query } from "react-apollo";

import CommonStyle from "common/styles/common.css";
import YbLoadingView from "common/components/yb-loading-view";
import YbLoading from "common/components/yb-loading";

import RootLayerWrapper from "../components/root-layer-wrapper";

import { GET_YIELD_SET_AND_ONBOARDED_UNITS } from "../query/queries";
import Style from "../styles/ad-units.css";

class OnboardingAdUnits extends React.Component {
  render() {
    const yieldSetId = parseInt(_.get(this.props, "match.params.yieldSetId"));
    // If not Reseller, publisherId will be undefined
    const publisherId = _.get(this.props, "match.params.publisherId");

    const queryVariables = {
      filter: {
        yieldSetIds: [yieldSetId],
      },
      pubId: publisherId,
    };

    return (
      <Query
        query={GET_YIELD_SET_AND_ONBOARDED_UNITS}
        variables={queryVariables}
        fetchPolicy={"network-only"}
      >
        {({ loading, error, data }) => {
          if (loading) {
            return <YbLoadingView />;
          }

          if (error) {
            console.log(error);
            return (
              <div style={{ color: "red" }}>
                Error Loading Yield Set Information
                <div style={{ fontSize: "14px" }}>
                  {error.message && JSON.stringify(error.message, null, 4)}
                </div>
              </div>
            );
          }

          if (data) {
            const ys = _.get(data, "yieldSets.edges[0].node");
            const gamNetworkId = _.get(ys, "gamNetwork.networkId");

            if (!ys) {
              return `Sorry Yield Set ${yieldSetId} does not exist.`;
            }

            const onboardedExtUnitIds = _(_.get(data, "units.edges", []))
              .map((item) => {
                return _.get(item, "node.extId");
              })
              .compact()
              .value();

            return (
              <div className={CommonStyle.wrapper}>
                <div className={CommonStyle.pageTitle}>
                  New Ad Unit for <span title={yieldSetId}>{ys.name}</span>
                </div>

                <div style={{ padding: "15px" }}>
                  {/* <div>
                    This publisher already has {onboardedExtUnitIds.length} 
                    onboarded units.
                  </div> */}

                  <RootLayerWrapper
                    yieldSetId={yieldSetId}
                    gamNetworkId={gamNetworkId}
                    publisherId={publisherId}
                    onboardedExtUnitIds={onboardedExtUnitIds}
                  ></RootLayerWrapper>
                </div>
              </div>
            );
          }
        }}
      </Query>
    );
  }
}

module.exports = OnboardingAdUnits;
