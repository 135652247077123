import React from "react";
import { Query } from "react-apollo";
import CommonStyle from "../../../common/styles/common.css";

import { QUERY_HOME_DASHBOARD } from "../query/home-dashboard";

import YbLoading from "common/components/yb-loading";
import HomeDashboardView from "./home-dashboard-view";

class HomeDashboard extends React.Component {
  render() {
    return (
      <Query query={QUERY_HOME_DASHBOARD} fetchPolicy={"network-only"}>
        {({ loading, error, data, refetch }) => {
          if (loading) {
            return (
              <div className={CommonStyle.textAlignCenter}>
                <YbLoading></YbLoading>
              </div>
            );
          }

          if (error) {
            return (
              <div
                style={{
                  textAlign: "center",
                  padding: "56px",
                  minHeight: "100vh",
                }}
              >
                <p>It seems we have no data for Home Dashboard yet.</p>

                <p>
                  Please come back later, we should have enough data in another
                  hour.
                </p>
              </div>
            );
          }

          const reportsUrl = _.get(data, "homeDashboard.signedUrl");
          if (data && reportsUrl) {
            return (
              <HomeDashboardView reportsUrl={reportsUrl}></HomeDashboardView>
            );
          }

          return "";
        }}
      </Query>
    );
  }
}

const styles = {
  fixedWidth: {
    padding: "8px 24px",
    maxWidth: "1280px",
    margin: "auto",
  },
};

export default HomeDashboard;
