import React from "react";
import _ from "lodash";
import { Query } from "react-apollo";
import cogoToast from "cogo-toast";

import { withRouter } from "react-router-dom";
import YbLoading from "common/components/yb-loading";
// import YbLoadingView from "common/components/yb-loading-view";
import YbSearch from "common/components/yb-search";

import Style from "../styles/ad-units.css";
import CommonStyle from "common/styles/common.css";
import FormStyle from "common/styles/forms.css";
import ItemStyle from "common/styles/inventory-item.css";
import YbTooltip from "common/components/yb-tooltip";
import TreeStyle from "common/styles/tree-selector-v3.css";

import { GET_UNIT_CANDIDATES } from "../query/queries";
import { formatCandidates } from "../helpers/ad-unit-candidate-formatter";

import YbReqStatTooltip from "../../../ad-units/components/yb-req-stat-tooltip";
import YbReqStatDiagram from "../../../ad-units/components/yb-req-stat-diagram";

import OnboardFormWrapper from "./onboard-form-wrapper";

const ITEMS_PER_PAGE = 500;

const ONBOARD_LIMIT_UNIT_COUNT = 1000;
const ONBOARD_LIMIT_EXCEED_ERROR_MESSAGE = `Sorry, we are unable to onboard more than ${ONBOARD_LIMIT_UNIT_COUNT} ad units at a time.`;

// the error passed back by graphql
const GRAPHQL_LIMIT_EXCEED_ERROR_MSG =
  "GraphQL error: Unit count limit exceeded when recursively search descendants. Limit: 1000";

function _toastErrorMessage(errorMessage) {
  cogoToast.error(errorMessage, {
    hideAfter: 3,
    heading: "Error Loading Ad Units",
  });
}

class AdUnitsOnboardHandler extends React.Component {
  constructor(props) {
    super(props);

    const { rootLayerCandidates } = props;
    this.state = {
      // for top selector
      layers: [rootLayerCandidates],

      // to remember the units in each layer that has been expanded
      drilledDownUnits: [],

      // for bottom selected items table
      selectedItems: [],

      //
      activeQuery: null,
    };

    this._onAddUnit = this._onAddUnit.bind(this);
    this._onRemoveUnit = this._onRemoveUnit.bind(this);
    // for layer header
    this.onAddUnits = this.onAddUnits.bind(this);
    this.onRemoveUnits = this.onRemoveUnits.bind(this);

    // for top selector
    this.onSelectUnit = this.onSelectUnit.bind(this);
    this.onSelectAllChildUnits = this.onSelectAllChildUnits.bind(this);
    this.onDrillDown = this.onDrillDown.bind(this);

    // for bottom selected items table
    this.onRemoveSelectedUnit = this.onRemoveSelectedUnit.bind(this);
    this.onRemoveAllSelectedUnits = this.onRemoveAllSelectedUnits.bind(this);

    this.onBackToSummaryPage = this.onBackToSummaryPage.bind(this);
  }

  onBackToSummaryPage() {
    const { publisherId } = this.props;
    let overviewRoute = "/summary/overview";
    if (publisherId > 0) {
      overviewRoute = `/publisher/${publisherId}/summary/overview`;
    }

    return this.props.history.push(overviewRoute);
  }

  _getUnitFullPath(unit) {
    return `${unit.parentPath === "/" ? "" : unit.parentPath}/${unit.name}`;
  }

  _findRootUnit(targetUnit) {
    // let rootUnitName;
    // if (targetUnit.parentPath === "/") {
    //   rootUnitName = targetUnit.name;
    // } else {
    //   rootUnitName = targetUnit.parentPath.split("/")[1];
    // }
    // let rootUnit = _.find(this.props.rootLayerCandidates, (unit) => {
    //   return unit.name === rootUnitName;
    // });
    // return rootUnit;
    let currentUnit = targetUnit;
    while (currentUnit.parentUnit) {
      currentUnit = currentUnit.parentUnit;
    }
    return currentUnit;
  }

  // targetUnit is leaf
  _recursivelyAddParentSelectedDirectCount(targetUnit) {
    // let currentUnit = targetUnit;
    // while (currentUnit.parentUnit) {
    //   if (
    //     currentUnit.selectedDirectCount ===
    //     currentUnit.selectableDirectChildUnitCount
    //   ) {
    //     ++currentUnit.parentUnit.selectedDirectCount;
    //   } else {
    //     break;
    //   }
    //   currentUnit = currentUnit.parentUnit;
    // }

    let currentUnit = targetUnit;
    let fullDelta =
      currentUnit.selectedDirectCount ===
      currentUnit.selectableDirectChildUnitCount
        ? 1
        : 0;
    let partialDelta = 0;
    while (currentUnit.parentUnit && (fullDelta !== 0 || partialDelta !== 0)) {
      const pu = currentUnit.parentUnit;
      const oldSelectedDirectCount = pu.selectedDirectCount;
      const oldSelectedPartialCount = pu.selectedPartialDirectCount;

      pu.selectedDirectCount += fullDelta;
      pu.selectedPartialDirectCount += partialDelta;

      if (pu.selectedDirectCount === 0 && pu.selectedPartialDirectCount === 0) {
        pu.checkboxStatus = "NOT_SELECTED";
      } else {
        pu.checkboxStatus = "SELECTED";
      }

      // <1, 0> ==> simply we have a full child
      // <0, 1> ==> we have a partial child
      // <1, -1> ==> we have a child changing from partial to full
      if (fullDelta === 1) {
        // full after add 1
        if (oldSelectedDirectCount === pu.selectableDirectChildUnitCount - 1) {
          fullDelta = 1;

          // Previous, we report partial so we should subtract it.
          if (pu.selectableDirectChildUnitCount > 1) {
            partialDelta = -1;
          }
          // No need to subtract back
          else {
            partialDelta = 0;
          }
        }
        // becoming partial full
        else if (
          oldSelectedDirectCount === 0 &&
          oldSelectedPartialCount === 0
        ) {
          fullDelta = 0;
          partialDelta = 1;
        } else {
          fullDelta = 0;
          partialDelta = 0;
        }
      } else if (partialDelta === 1) {
        if (oldSelectedPartialCount === 0 && oldSelectedDirectCount === 0) {
          fullDelta = 0;
          partialDelta = 1;
        } else {
          fullDelta = partialDelta = 0;
        }
      } else {
        fullDelta = partialDelta = 0;
      }

      currentUnit = pu;
    }
  }

  // targetUnit is leaf
  _recursivelySubtractParentSelectedDirectCount(targetUnit) {
    // let currentUnit = targetUnit;

    // // only when parent's checkbox status changed from selected to not selected or partially selected
    // // do we need to update parent's parent checkbox status
    // while (currentUnit.parentUnit) {
    //   const shouldSubtractNextParent =
    //     currentUnit.parentUnit.selectedDirectCount ===
    //     currentUnit.parentUnit.selectableDirectChildUnitCount; // checkbox is selected
    //   --currentUnit.parentUnit.selectedDirectCount;
    //   if (!shouldSubtractNextParent) break;

    //   currentUnit = currentUnit.parentUnit;
    // }

    let currentUnit = targetUnit;
    let fullDelta = -1;
    let partialDelta = 0;

    // only when parent's checkbox status changed from selected to not selected or partially selected
    // do we need to update parent's parent checkbox status
    while (currentUnit.parentUnit && (fullDelta !== 0 || partialDelta !== 0)) {
      const pu = currentUnit.parentUnit;
      const oldSelectedDirectCount = pu.selectedDirectCount;
      const oldSelectedPartialCount = pu.selectedPartialDirectCount;

      pu.selectedDirectCount += fullDelta;
      pu.selectedPartialDirectCount += partialDelta;

      //
      if (pu.selectedDirectCount === 0 && pu.selectedPartialDirectCount === 0) {
        pu.checkboxStatus = "NOT_SELECTED";
      } else {
        pu.checkboxStatus = "SELECTED";
      }

      // <-1, 0>
      // <-1, 1>
      // <0, -1>
      if (fullDelta === -1) {
        if (oldSelectedDirectCount === pu.selectableDirectChildUnitCount) {
          // From full to partial <-1, 1>
          if (pu.selectableDirectChildUnitCount > 1) {
            fullDelta = -1;
            partialDelta = 1;
          }
          // From full to empty <-1, 0>
          else {
            fullDelta = -1;
            partialDelta = 0;
          }
        }
        // From partial to empty
        else if (
          oldSelectedDirectCount === 1 &&
          oldSelectedPartialCount === 0 &&
          partialDelta === 0
        ) {
          fullDelta = 0;
          partialDelta = -1;
        } else {
          fullDelta = partialDelta = 0;
        }
      } else if (partialDelta === -1) {
        // Partial to empty
        if (oldSelectedPartialCount === 1 && oldSelectedDirectCount === 0) {
          fullDelta = 0;
          partialDelta = -1;
        }
        // Partial to partial => No change!
        else {
          fullDelta = partialDelta = 0;
        }
      } else {
        fullDelta = partialDelta = 0;
      }

      currentUnit = pu;
    }
  }

  onRemoveSelectedUnit(targetUnit) {
    this.onSelectUnit(targetUnit);
  }

  onRemoveAllSelectedUnits() {
    // clear all units' selectedDirectCount
    for (let ru of this.props.rootLayerCandidates) {
      ru.selectedDirectCount = ru.selectedPartialDirectCount = 0;
      ru.checkboxStatus = "NOT_SELECTED";
      if (ru.childUnits) {
        for (let c of ru.childUnits) {
          c.selectedDirectCount = c.selectedPartialDirectCount = 0;
          c.checkboxStatus = "NOT_SELECTED";
        }
      }
    }

    setTimeout(() => {
      this.setState({
        selectedItems: [],
      });
    });
  }

  // todo: rename... should be "toggle" not "select"
  // leaf only
  // hasChildren: false
  // isCompatible: true
  // isOnboarded: false
  onSelectUnit(targetUnit) {
    let newSelectedItems = [];
    // unselect if unit is selected
    if (targetUnit.checkboxStatus === "SELECTED") {
      targetUnit.checkboxStatus = "NOT_SELECTED";

      this._recursivelySubtractParentSelectedDirectCount(targetUnit);

      newSelectedItems = _.without(this.state.selectedItems, targetUnit);

      // --targetUnit.parentUnit.selectedDirectCount;
    } else {
      // select if unit is not selected
      // since this is leaf only, there will be no partially selected status
      targetUnit.checkboxStatus = "SELECTED";

      this._recursivelyAddParentSelectedDirectCount(targetUnit);

      newSelectedItems = [...this.state.selectedItems, targetUnit];
    }

    this.setState({
      selectedItems: newSelectedItems,
    });
  }

  _onAddUnit(targetUnit) {
    // select if unit is not selected
    // since this is leaf only, there will be no partially selected status
    targetUnit.checkboxStatus = "SELECTED";

    this._recursivelyAddParentSelectedDirectCount(targetUnit);

    const newSelectedItems = [...this.state.selectedItems, targetUnit];
    this.setState({
      selectedItems: newSelectedItems,
    });
  }

  _onRemoveUnit(targetUnit) {
    targetUnit.checkboxStatus = "NOT_SELECTED";

    this._recursivelySubtractParentSelectedDirectCount(targetUnit);

    let newSelectedItems = _.without(this.state.selectedItems, targetUnit);

    // --targetUnit.parentUnit.selectedDirectCount;

    this.setState({
      selectedItems: newSelectedItems,
    });
  }

  onAddUnits(targetUnits) {
    for (let targetUnit of targetUnits) {
      // this._onAddUnit(targetUnit);
      targetUnit.checkboxStatus = "SELECTED";

      this._recursivelyAddParentSelectedDirectCount(targetUnit);
    }

    let newSelectedItems = _.union(this.state.selectedItems, targetUnits);
    this.setState({
      selectedItems: newSelectedItems,
    });
  }

  onRemoveUnits(targetUnits) {
    for (let targetUnit of targetUnits) {
      // this._onRemoveUnit(targetUnit);
      targetUnit.checkboxStatus = "NOT_SELECTED";

      this._recursivelySubtractParentSelectedDirectCount(targetUnit);
    }

    let newSelectedItems = _.difference(this.state.selectedItems, targetUnits);
    this.setState({
      selectedItems: newSelectedItems,
    });
  }

  onSelectAllChildUnits(targetUnit) {
    let goSelected = true;
    // Only full selected should toggle!
    if (
      targetUnit.checkboxStatus === "SELECTED" &&
      targetUnit.selectedDirectCount ===
        targetUnit.selectableDirectChildUnitCount
    ) {
      goSelected = false;
    } else {
      goSelected = true;
    }

    // all child units of targetUnit
    // change checkbox status
    // only leaf gets added to selectedItems

    // 1. find child units of targetUnit
    // 2. set all child units' checkbox status to selected
    // 3. filter leaf units inside child units to add to selectedItems

    // 1. find root unit
    // if targetUnit is in root layer: use name
    // if not in root layer: get root parent name from parentPath
    const rootUnit = this._findRootUnit(targetUnit);
    const fullPath = this._getUnitFullPath(targetUnit);

    const pendingOp = (queryError) => {
      if (queryError) {
        if (rootUnit.queryError) return;
        rootUnit.queryError = queryError;

        _toastErrorMessage(queryError.message);

        setTimeout(() => {
          this.setState({
            activeQuery: null,
          });
        });
        return;
      }

      let allChildUnits = _.filter(rootUnit.childUnits, (unit) => {
        return _.startsWith(unit.parentPath, fullPath);
      });

      // Add self!
      allChildUnits.push(targetUnit);

      let allLeafUnits = _.filter(allChildUnits, (unit) => {
        return !unit.hasChildren && unit.isCompatible && !unit.isOnboarded;
      });

      let allSelectableChildUnits = _.filter(allChildUnits, (unit) => {
        return unit.hasChildren || (unit.isCompatible && !unit.isOnboarded);
      });

      let newSelectedItems;
      // unselect all child units
      if (goSelected === false) {
        for (let u of allSelectableChildUnits) {
          // When unit is leaf or when parent unit has no selectable units
          // To avoid over updating, we should only update the lowest selectable nodes
          if (
            u.checkboxStatus !== "NOT_SELECTED" &&
            u.selectableDirectChildUnitCount === 0
          ) {
            this._recursivelySubtractParentSelectedDirectCount(u);
          }
        }
        for (let i = 0; i < allSelectableChildUnits.length; ++i) {
          allSelectableChildUnits[i].checkboxStatus = "NOT_SELECTED";
        }

        newSelectedItems = _.difference(this.state.selectedItems, allLeafUnits);
      } else {
        // selected or partially selected
        for (let u of allSelectableChildUnits) {
          // When unit is leaf or when parent unit has no selectable units
          // To avoid over updating, we should only update the lowest selectable nodes
          if (
            u.checkboxStatus !== "SELECTED" &&
            u.selectableDirectChildUnitCount === 0
          ) {
            this._recursivelyAddParentSelectedDirectCount(u);
          }
        }
        for (let i = 0; i < allSelectableChildUnits.length; ++i) {
          allSelectableChildUnits[i].checkboxStatus = "SELECTED";
        }

        // let newSelectedItems = _.uniq(
        //   _.flatten(this.state.selectedItems.concat(allLeafUnits))
        // );
        newSelectedItems = _.union(this.state.selectedItems, allLeafUnits);
      }

      setTimeout(() => {
        this.setState({
          activeQuery: null,
          selectedItems: newSelectedItems,
        });
      });
    };

    // 2. check if rootUnit.childUnits have been cached
    // if has childUnits -> step3
    // if not -> query
    if (rootUnit.queryError) {
      _toastErrorMessage(rootUnit.queryError.message);
    } else if (rootUnit.childUnits) {
      pendingOp();
    } else {
      // if child units have not been queried yet
      this.setState({
        activeQuery: {
          targetUnit,
          pendingOp,
        },
      });
    }
  }

  // layerNum: start from 1 (root layer = 1)
  onDrillDown(targetUnit, layerNum) {
    // console.log("onDrillDown", layerNum, targetUnit);

    // 1. find root unit
    // 2. check if rootUnit.childUnits have been cached
    // 3. find next layer units

    // 1. find root unit
    // if targetUnit is in root layer: use name
    // if not in root layer: get root parent name from parentPath

    const rootUnit = this._findRootUnit(targetUnit);
    // const fullPath = this._getUnitFullPath(targetUnit);

    const currentLayers = this.state.layers;
    const pendingOp = (queryError) => {
      if (queryError) {
        if (rootUnit.queryError) return;
        rootUnit.queryError = queryError;

        _toastErrorMessage(queryError.message);

        setTimeout(() => {
          this.setState({
            activeQuery: null,
          });
        });
        return;
      }

      const nextLayerUnits = targetUnit.directChildUnits;

      // update layers
      let layerCount = 0;
      let newLayers = [];
      while (layerCount < layerNum) {
        newLayers.push(currentLayers[layerCount]);
        ++layerCount;
      }
      newLayers.push(nextLayerUnits);

      // update drilledDownUnits
      for (let ddu of this.state.drilledDownUnits) {
        ddu.isDrilledDown = false;
      }
      layerCount = 0;
      let newDrilledDownUnits = [];
      while (layerCount < layerNum - 1) {
        const oldDrilledDownUnit = this.state.drilledDownUnits[layerCount];
        oldDrilledDownUnit.isDrilledDown = true;
        newDrilledDownUnits.push(oldDrilledDownUnit);
        ++layerCount;
      }
      newDrilledDownUnits.push(targetUnit);
      targetUnit.isDrilledDown = true;

      setTimeout(() => {
        this.setState({
          activeQuery: null,
          layers: newLayers,
          drilledDownUnits: newDrilledDownUnits,
        });
      });
    };

    // 2. check if rootUnit.childUnits have been cached
    // if has childUnits -> step3
    // if not -> query

    if (rootUnit.queryError) {
      _toastErrorMessage(rootUnit.queryError.message);
    } else if (rootUnit.childUnits) {
      pendingOp();
    } else {
      // if child units have not been queried yet
      this.setState({
        activeQuery: {
          targetUnit,
          pendingOp,
        },
      });
    }
  }

  render() {
    const {
      yieldSetId,
      gamNetworkId,
      publisherId,
      onboardedExtUnitIds,
      wizard,
    } = this.props;

    const { layers, activeQuery, selectedItems } = this.state;

    let queryComponent = "";

    if (activeQuery) {
      const targetUnit = activeQuery.targetUnit;
      const pendingOp = activeQuery.pendingOp;
      const queryVariables = {
        filter: {
          parentAdUnitIds: [targetUnit.extId],
          gamNetworkId: gamNetworkId,
          recursive: true,
        },
        pubId: publisherId,
      };

      queryComponent = (
        <Query
          query={GET_UNIT_CANDIDATES}
          variables={queryVariables}
          fetchPolicy={"network-only"}
        >
          {({ loading, error, data }) => {
            if (loading) {
              // return <YbLoading />;
              // return (
              //   <div className={Style.loadingInsideOverlay}>
              //     <YbLoading></YbLoading>
              //   </div>
              // );
              return (
                <div
                  // className={Style.loadingInsideOverlay}
                  style={{
                    zIndex: 999999,
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    backgroundColor: "#fff",
                    opacity: "0.8",
                    border: "1px solid gray",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "not-allowed",
                  }}
                  onClick={(event) => {
                    // console.log("overlay click");
                    event.stopPropagation();
                    return false;
                  }}
                >
                  <YbLoading></YbLoading>
                </div>
              );
            }

            if (error) {
              console.log(error);
              let queryError = {
                message:
                  "Failed to query ad units. Please contact our SE for support.",
              };
              if (error && error.message === GRAPHQL_LIMIT_EXCEED_ERROR_MSG) {
                queryError.message =
                  "Ad units limit exceeded. Please contact our SE for support.";
              }

              pendingOp(queryError);
              return "";
            }

            let candidates = formatCandidates(
              data.extItems,
              onboardedExtUnitIds,
              targetUnit
            );
            targetUnit.childUnits = candidates;

            pendingOp();

            return "";
          }}
        </Query>
      );
    }

    const isSubmitDisabled = selectedItems.length > ONBOARD_LIMIT_UNIT_COUNT;

    return (
      <React.Fragment>
        <div>
          <div className={Style.loadingOverlayWrapper}>
            {queryComponent}

            <TreeLayers
              layers={layers}
              onSelectUnit={this.onSelectUnit}
              onSelectAllChildUnits={this.onSelectAllChildUnits}
              onDrillDown={this.onDrillDown}
              onAddUnits={this.onAddUnits}
              onRemoveUnits={this.onRemoveUnits}
            ></TreeLayers>
          </div>

          <SelectedItemsTable
            selectedItems={selectedItems}
            isSubmitDisabled={isSubmitDisabled}
            onRemoveSelectedUnit={this.onRemoveSelectedUnit}
            onRemoveAllSelectedUnits={this.onRemoveAllSelectedUnits}
          ></SelectedItemsTable>

          <div>
            <OnboardFormWrapper
              yieldSetId={yieldSetId}
              gamNetworkId={gamNetworkId}
              publisherId={publisherId}
              selectedItems={selectedItems}
              isSubmitDisabled={isSubmitDisabled}
              shouldHideCancelBtn={wizard ? true : false} // wizard has its own prev step btn
              callbackAfterOnboardSuccess={
                wizard
                  ? () => wizard.nextStepFnForStep4(selectedItems)
                  : () => {
                      this.onBackToSummaryPage();
                    }
              }
              wizard={wizard}
            ></OnboardFormWrapper>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function SelectedItemsTable(props) {
  let {
    selectedItems,
    isSubmitDisabled,

    // actions
    onRemoveSelectedUnit,
    onRemoveAllSelectedUnits,
  } = props;

  if (!selectedItems.length) {
    return <span />;
  }

  // Sort candidates!
  selectedItems = _.orderBy(selectedItems, ["dailyCompatibleAvgReq"], ["desc"]);

  return (
    <React.Fragment>
      <div
        style={{ marginTop: "24px" }}
        // className={FormStyle.row}
      >
        <div className={FormStyle.label}>
          {i18n`SELECTED_AD_UNITS`} ({selectedItems.length})
          <span style={{ marginLeft: "12px" }}>
            <button
              className={CommonStyle.buttonLink}
              onClick={onRemoveAllSelectedUnits}
            >
              <i className="fa fa-times" /> {i18n`REMOVE_ALL`}
            </button>
          </span>
        </div>

        {isSubmitDisabled && (
          <div className="alert alert-danger">
            {ONBOARD_LIMIT_EXCEED_ERROR_MESSAGE}
          </div>
        )}

        <div className={Style.selectedAdUnits}>
          {selectedItems.map((item) => {
            return (
              <div className={ItemStyle.selectedRow} key={item.extId}>
                <div className={ItemStyle.selectedRowStart}>
                  <span style={{ marginRight: "12px" }}>
                    <button
                      className={CommonStyle.buttonLink}
                      onClick={() => onRemoveSelectedUnit(item)}
                    >
                      <i className="fa fa-times" />
                    </button>
                  </span>

                  <div className={ItemStyle.unitName} title={item.extId}>
                    {item.fullPath}
                  </div>
                </div>

                <div className={ItemStyle.itemStat}>
                  <YbReqStatTooltip
                    item={item}
                    tooltipKey={item.extId + "-selected-tooltip"}
                  >
                    <YbReqStatDiagram item={item}></YbReqStatDiagram>
                  </YbReqStatTooltip>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </React.Fragment>
  );
}

function ItemComponent(props) {
  const { item, layerNum, onSelectUnit, onSelectAllChildUnits, onDrillDown } =
    props;

  let unitItemClass = ItemStyle.unitItem;

  // TODO: check these classes
  if (item.isOnboarded && !item.hasChildren) {
    unitItemClass += " " + TreeStyle.nodeOnboarded;
  } else if (!item.isCompatible && !item.hasChildren) {
    unitItemClass += " " + TreeStyle.nodeNotCompatible;
  } else {
    unitItemClass += " " + TreeStyle.node;
  }

  if (item.isDrilledDown) {
    unitItemClass += " " + TreeStyle.opened;
  }

  if (item.isOnboarded && !item.hasChildren) {
    unitItemClass = ItemStyle.unitItemOnboarded;
  } else if (!item.isCompatible && !item.hasChildren) {
    unitItemClass = ItemStyle.unitItemNotCompatible;
  } else if (item.queryError) {
    unitItemClass = ItemStyle.unitItemHasError;
  }

  let checkboxStatus = "NOT_SELECTED";
  // leaf
  if (!item.hasChildren) {
    checkboxStatus = item.checkboxStatus;
  }
  // parent
  else {
    if (item.selectableDirectChildUnitCount === item.selectedDirectCount) {
      if (item.selectedDirectCount === 0) {
        checkboxStatus = item.checkboxStatus;
      } else {
        checkboxStatus = "SELECTED";
      }
    } else if (
      item.selectedDirectCount > 0 ||
      item.selectedPartialDirectCount > 0
    ) {
      checkboxStatus = "PARTIALLY_SELECTED";
    } else {
      checkboxStatus = "NOT_SELECTED";
    }
  }

  let checkboxClass = ItemStyle.checkbox;
  if (item.isOnboarded) {
    checkboxClass += " " + ItemStyle.isOnboarded;
  } else if (checkboxStatus === "SELECTED") {
    checkboxClass += " " + ItemStyle.selected;
  } else if (checkboxStatus === "PARTIALLY_SELECTED") {
    checkboxClass += " " + ItemStyle.partiallySelected;
  } else if (item.hasChildren) {
    checkboxClass += " " + ItemStyle.isParent;
  }

  let checkboxElement = "";
  if (item.queryError) {
    checkboxElement = (
      <YbTooltip message={item.queryError.message} position="right">
        <span style={{ color: "red" }}>
          <i className="fa fa-exclamation-triangle"></i>
        </span>
      </YbTooltip>

      // // <button type="button" className={checkboxClass}>
      // <div style={{ color: "red" }}>
      //   <i className="fa fa-exclamation-triangle"></i>
      // </div>
      // </button>
    );
  } else if (item.isOnboarded && !item.hasChildren) {
    checkboxElement = (
      <YbTooltip message="Already onboarded" position="right">
        <button type="button" className={checkboxClass}>
          <i className="fa fa-check"></i>
        </button>
      </YbTooltip>
    );
  } else if (!item.hasChildren && item.isCompatible) {
    checkboxElement = (
      <button
        type="button"
        className={checkboxClass}
        onClick={(event) => {
          onSelectUnit(item);
          event.stopPropagation();
        }}
      >
        <i className="fa fa-check"></i>
      </button>
    );
  } else if (item.hasChildren) {
    if (checkboxStatus === "PARTIALLY_SELECTED") {
      checkboxElement = (
        <YbTooltip message="Select all child units" position="right">
          <div
            className={checkboxClass}
            onClick={(event) => {
              onSelectAllChildUnits(item);
              event.stopPropagation();
            }}
          >
            <div className={ItemStyle.checkboxDot}></div>
          </div>
        </YbTooltip>
      );
    } else {
      checkboxElement = (
        <YbTooltip message="Select all child units" position="right">
          <button
            type="button"
            className={checkboxClass}
            onClick={(event) => {
              onSelectAllChildUnits(item);
              event.stopPropagation();
            }}
          >
            <i className="fa fa-check"></i>
          </button>
        </YbTooltip>
      );
    }
  }

  function onClickableAreaClicked(item) {
    if (item.queryError) return;
    if (!item.hasChildren && item.isOnboarded) return;
    if (!item.hasChildren && !item.isCompatible) return;

    if (item.hasChildren) {
      return onDrillDown(item, layerNum);
    }

    if (item.isCompatible && !item.isOnboarded) {
      return onSelectUnit(item);
    }
  }

  return (
    <div className={unitItemClass} onClick={() => onClickableAreaClicked(item)}>
      <div className={ItemStyle.checkboxWrapper}>{checkboxElement}</div>

      <div className={ItemStyle.clickableArea}>
        <div className={ItemStyle.itemName} title={item.extId}>
          {/* {item.queryError && (
            <YbTooltip message={item.queryError.message} position="right">
              <span style={{ color: "red", marginRight: "4px" }}>
                <i className="fa fa-exclamation-triangle"></i>
              </span>
            </YbTooltip>
          )} */}
          {item.name}
          {/* <div>
            {item.selectedDirectCount} / {item.selectableDirectChildUnitCount}
          </div> */}
        </div>

        <div className={ItemStyle.itemStat}>
          <YbReqStatTooltip
            item={item}
            tooltipKey={item.extId + "-req-tooltip"}
          >
            <YbReqStatDiagram item={item}></YbReqStatDiagram>
          </YbReqStatTooltip>
        </div>

        <div className={ItemStyle.arrowWrapper}>
          {item.hasChildren && <i className="fa fa-angle-right"></i>}
        </div>
      </div>
    </div>
  );
}

class TreeLayer extends React.Component {
  constructor(props) {
    super(props);

    let paginatedCandidates = _.chunk(this.props.candidates, ITEMS_PER_PAGE);
    let currentPageCount = 0;

    this.state = {
      searchText: "",
      // filteredCandidates: this.props.candidates,
      filteredCandidates: paginatedCandidates[currentPageCount],
      paginatedCandidates,
      currentPageCount: ++currentPageCount,
      totalPageCount: paginatedCandidates.length,
    };

    this.onFilterCandidates = _.debounce(
      this.onFilterCandidates.bind(this),
      500
    );
    this.toggleSelectAllLeafUnits = this.toggleSelectAllLeafUnits.bind(this);
    this.searchTextChange = this.searchTextChange.bind(this);

    this.onShowMoreCandidates = this.onShowMoreCandidates.bind(this);
  }

  componentDidUpdate(prevProps) {
    // console.log(
    //   "componentDidUpdate",
    //   prevProps,
    //   this.props,
    //   prevProps.candidates === this.props.candidates
    // );

    if (prevProps.candidates !== this.props.candidates) {
      let paginatedCandidates = _.chunk(this.props.candidates, ITEMS_PER_PAGE);
      let currentPageCount = 0;

      this.setState({
        searchText: "",
        isSearching: false,
        // filteredCandidates: this.props.candidates,
        filteredCandidates: paginatedCandidates[currentPageCount],
        paginatedCandidates,
        currentPageCount: ++currentPageCount,
        totalPageCount: paginatedCandidates.length,
      });
    }
  }

  searchTextChange(value) {
    this.setState({
      searchText: value,
      isSearching: true,
    });

    this.onFilterCandidates(value);
  }

  onFilterCandidates(searchText) {
    // console.log("onfilter", searchText);
    let regexp = new RegExp(searchText, "i");
    let filteredCandidates = _.filter(this.props.candidates, (item) => {
      // still show item that is being drilled down
      return item.isDrilledDown || item.name.match(regexp);
    });

    if (searchText === "") {
      let currentPageCount = 0;

      this.setState({
        isSearching: false,
        // filteredCandidates: this.props.candidates,
        filteredCandidates: this.state.paginatedCandidates[currentPageCount],
        // paginatedCandidates,
        currentPageCount: ++currentPageCount,
        totalPageCount: this.state.paginatedCandidates.length,
      });
    } else {
      this.setState({
        isSearching: false,
        filteredCandidates,
        currentPageCount: 0,
        totalPageCount: 0,
      });
    }
  }

  onShowMoreCandidates() {
    let newCandidates =
      this.state.paginatedCandidates[this.state.currentPageCount];
    this.setState({
      filteredCandidates: [...this.state.filteredCandidates, ...newCandidates],
      currentPageCount: ++this.state.currentPageCount,
    });
  }

  toggleSelectAllLeafUnits() {
    // select from filtered list? Not now
    let leafUnits = _.filter(this.props.candidates, (item) => {
      return !item.hasChildren && item.isCompatible && !item.isOnboarded;
    });
    if (leafUnits.length === 0) return;

    const { onAddUnits, onRemoveUnits } = this.props;

    if (this.props.isAllLeafSelected) {
      return onRemoveUnits(leafUnits);
    } else {
      return onAddUnits(leafUnits);
    }
  }

  render() {
    const {
      candidates,
      layerNum,
      isAllLeafSelected,

      onSelectUnit,
      onSelectAllChildUnits,
      onDrillDown,
    } = this.props;

    if (candidates.length > 0) {
      // Sort candidates!
      let filteredCandidates = _.orderBy(
        this.state.filteredCandidates,
        ["hasChildren", "isCompatible", "dailyCompatibleAvgReq"],
        ["desc", "desc", "desc"]
      );

      const totalCandidates = _.flatten(this.state.paginatedCandidates).length;

      return (
        <React.Fragment>
          <TreeLayerHeader
            toggleSelectAllLeafUnits={this.toggleSelectAllLeafUnits}
            searchTextChange={this.searchTextChange}
            searchText={this.state.searchText}
            isAllLeafSelected={isAllLeafSelected}
          ></TreeLayerHeader>

          {/* <div>
              current page count: {this.state.currentPageCount}/
              {this.state.totalPageCount}
            </div>
            <div>
              candidates count: {filteredCandidates.length}/{totalCandidates}
            </div> */}

          {!this.state.isSearching && this.state.searchText && (
            <div
              style={{
                fontSize: "16px",
                color: "#333333",
                padding: "8px",
                borderTop: "1px solid darkgray",
              }}
            >
              <div>
                Search result matched <b>{filteredCandidates.length}</b> out of{" "}
                {totalCandidates} ad units
              </div>
            </div>
          )}

          {this.state.isSearching ? (
            <div
              style={{
                borderTop: "1px solid darkgray",
              }}
            >
              Searching <i>{this.state.searchText}</i>...
            </div>
          ) : (
            <div className={TreeStyle.itemList}>
              {filteredCandidates.map((candidate) => {
                return (
                  <ItemComponent
                    key={candidate.extId}
                    item={candidate}
                    layerNum={layerNum}
                    onSelectUnit={onSelectUnit}
                    onSelectAllChildUnits={onSelectAllChildUnits}
                    onDrillDown={onDrillDown}
                  ></ItemComponent>
                );
              })}

              {this.state.currentPageCount < this.state.totalPageCount && (
                <button
                  type="button"
                  onClick={this.onShowMoreCandidates}
                  className={ItemStyle.showMoreItemsBtn}
                >
                  Show more ad units
                  <span style={{ fontSize: "smaller", marginLeft: "8px" }}>
                    <i className="fa fa-arrow-down"></i>
                  </span>
                </button>
              )}
            </div>
          )}
        </React.Fragment>
      );
    } else {
      return <NoActiveUnits></NoActiveUnits>;
    }
  }
}

class TreeLayerHeader extends React.PureComponent {
  render() {
    const {
      toggleSelectAllLeafUnits,
      searchTextChange,
      searchText,
      isAllLeafSelected,
    } = this.props;

    return (
      <div className={TreeStyle.columnHeader}>
        <span className={TreeStyle.checkAllWrapper}>
          {/* show tooltip when button not disabled */}
          {searchText ? (
            <button
              type="button"
              disabled={searchText}
              className={CommonStyle.button}
              onClick={toggleSelectAllLeafUnits}
            >
              <i
                className={`fa ${
                  isAllLeafSelected ? "fa-check-square-o" : "fa-square-o"
                }`}
              ></i>
            </button>
          ) : (
            <YbTooltip message="Select all leaf units" position="top">
              <button
                type="button"
                disabled={searchText}
                className={CommonStyle.button}
                onClick={toggleSelectAllLeafUnits}
              >
                <i
                  className={`fa ${
                    isAllLeafSelected ? "fa-check-square-o" : "fa-square-o"
                  }`}
                ></i>
              </button>
            </YbTooltip>
          )}
        </span>

        <span className={TreeStyle.seachInputWrapper}>
          <YbSearch
            value={searchText}
            type="text"
            placeholder={i18n`SEARCH_BY_NAME_ELLIPSIS`}
            onChange={searchTextChange}
          />
        </span>
      </div>
    );
  }
}

function NoActiveUnits(props) {
  return (
    <a
      className={`col-md-12 ${TreeStyle.tableColumn} ${CommonStyle.accesibleItem}`}
      disabled
    >
      <div
        className={`row ${TreeStyle.node} ${TreeStyle.leaf} ${TreeStyle.noItems}`}
      >
        <div className="col-md-12" style={{ textAlign: "center" }}>
          No active units
        </div>
      </div>
    </a>
  );
}

function TreeLayers(props) {
  const {
    layers,

    // actions
    onSelectUnit,
    onSelectAllChildUnits,
    onDrillDown,

    onAddUnits,
    onRemoveUnits,
  } = props;

  // TODO: the width of each layer if too many layers
  const layerWidthPerc = 100 / layers.length;

  function _isAllLeafSelected(candidates) {
    let selectedUnitsCount = 0;
    let selectableUnitsCount = 0;

    for (let i = 0; i < candidates.length; ++i) {
      const item = candidates[i];
      if (!item.hasChildren && item.isCompatible && !item.isOnboarded) {
        ++selectableUnitsCount;

        if (item.checkboxStatus === "SELECTED") {
          ++selectedUnitsCount;
        }
      }
    }

    return selectedUnitsCount === selectableUnitsCount;
  }

  return (
    <div className={`${CommonStyle.table} ${TreeStyle.treeSelector}`}>
      <div
        // className={`row ${CommonStyle.tableRowNoHover} ${
        //   TreeStyle["columns" + layers.length]
        // }`}
        className={`row ${CommonStyle.tableRowNoHover}`}
        // style={{
        //   display: "flex",
        //   flexDirection: "row",
        //   flexWrap: "wrap",
        //   // overflowX: "auto"
        // }}
      >
        {layers.map((layerItems, layerIndex) => {
          const isAllLeafSelected = _isAllLeafSelected(layerItems);

          return (
            <div
              key={layerIndex}
              style={{
                width: `${layerWidthPerc}%`,
                border: "0.5px solid lightgray",
              }}
            >
              <TreeLayer
                candidates={layerItems}
                layerNum={layerIndex + 1}
                isAllLeafSelected={isAllLeafSelected}
                onSelectUnit={onSelectUnit}
                onSelectAllChildUnits={onSelectAllChildUnits}
                onDrillDown={onDrillDown}
                onAddUnits={onAddUnits}
                onRemoveUnits={onRemoveUnits}
              ></TreeLayer>
            </div>
          );
        })}
      </div>
    </div>
  );
}

module.exports = withRouter(AdUnitsOnboardHandler);
