import React, { useState, useEffect } from "react";
import { Card, Button, Form, Col } from "react-bootstrap";
import { useMutation } from "react-apollo-hooks";
import _ from "lodash";
import cogoToast from "cogo-toast";
import PublisherFormStepItem from "./publisher-form-step-item";
import PublisherFormBasic from "./publisher-form-basic";
import PublisherFormGAMProfile from "./publisher-form-gam-profile";
import PublisherFormInvite from "./publisher-form-invite";
import { CREATE_UNREGISTERED_PUBLISHER } from "../query/publishers";

const defaultStepConfig = {
  step1: {
    title: "1. Basic Information",
    isFinished: false,
    isCurrent: true
  },
  step2: {
    title: "2. GAM Profile",
    isFinished: false,
    isCurrent: false
  },
  step3: {
    title: "3. Invite Publisher!",
    isFinished: false,
    isCurrent: false,
    invitationLink: ""
  }
};

const defaultPublisher = {
  // Basic
  publisherName: "",
  websiteUrls: [""],
  // GAM Profile
  googleAccountType: "", // the real value matters to backend
  gamLogoType: "",
  hasBillingSection: "",
  geoCountryId: 45 // 45 is Taiwan
};

function PublisherForm(props) {
  const handleCancel = props.handleCancel;
  const [step1Config, setStep1Config] = useState(defaultStepConfig.step1);
  const [step2Config, setStep2Config] = useState(defaultStepConfig.step2);
  const [step3Config, setStep3Config] = useState(defaultStepConfig.step3);

  const [newPublisher, setNewPublisher] = useState(defaultPublisher);

  const createUnregisteredPublisher = useMutation(
    CREATE_UNREGISTERED_PUBLISHER
  );

  function handlePublisherFormSubmit(values) {
    console.log("----handlePbulisherSumbit-----", values);
    // remove blank urls
    values.websiteUrls = _.compact(values.websiteUrls);
    // const hashPublisherUniqueID = btoa(`I2W-invite-${values.publisherName}`);
    // const encoded = encodeURIComponent(hashPublisherUniqueID);

    createUnregisteredPublisher({
      variables: {
        input: {
          publisherName: values.publisherName,
          googleAccountType: values.googleAccountType,
          geoCountryId: values.geoCountryId,
          websites: values.websiteUrls,
          // redirectUrl: `${window.location.origin}/#/invite/${encoded}`
          redirectUrl: `${window.location.origin}/#/invite/signup`
        }
      }
    })
      .then(response => {
        const invitationLink = _.get(
          response,
          "data.createInvitationLink.link"
        );

        setStep2Config({
          ...step2Config,
          isFinished: true,
          isCurrent: false
        });

        setStep3Config({
          ...step3Config,
          isCurrent: true,
          invitationLink
        });
      })
      .catch(err => {
        console.log("EROROOROROROR", err);
        cogoToast.error(
          `Something wrong happened to our server! ${err.message}`,
          {
            hideAfter: 15
          }
        );
      });
  }

  function handlePreviousStep(values) {
    console.log("----previous step-----", values);
    setNewPublisher(values);

    setStep1Config({
      ...step1Config,
      isFinished: false,
      isCurrent: true
    });

    setStep2Config({
      ...step2Config,
      isCurrent: false
    });
  }

  function handleNextStep(values) {
    console.log("----next step ----", values);
    setNewPublisher(values);
    setStep1Config({
      ...step1Config,
      isFinished: true,
      isCurrent: false
    });

    setStep2Config({
      ...step2Config,
      isCurrent: true
    });
  }

  function handleDone() {
    props.refreshPublishers();
  }

  return (
    <React.Fragment>
      <div>
        <div className="d-flex">
          <PublisherFormStepItem stepConfig={step1Config} />
          <PublisherFormStepItem stepConfig={step2Config} />
          <PublisherFormStepItem stepConfig={step3Config} />
        </div>
      </div>
      <div style={{ marginTop: "16px" }}>
        <Card className="p-4" bg="light">
          <Card.Body>
            {step1Config.isCurrent && (
              <PublisherFormBasic
                defaultPublisher={newPublisher}
                handleNextStep={handleNextStep}
                handleCancel={handleCancel}
              />
            )}
            {step2Config.isCurrent && (
              <PublisherFormGAMProfile
                defaultPublisher={newPublisher}
                handlePreviousStep={handlePreviousStep}
                handleCancel={handleCancel}
                handlePublisherFormSubmit={handlePublisherFormSubmit}
              />
            )}
            {step3Config.isCurrent && (
              <PublisherFormInvite
                publisher={newPublisher}
                invitationLink={step3Config.invitationLink}
                handleDone={handleDone}
              />
            )}
          </Card.Body>
        </Card>
        {/* <pre>{JSON.stringify(newPublisher, null, 4)}</pre> */}
      </div>
    </React.Fragment>
  );
}

export default PublisherForm;
