import React, { useState } from "react";
import { Card, Button, Form, Col, ListGroup } from "react-bootstrap";
import { Formik, Form as FormikForm } from "formik";
import * as Yup from "yup";
import _ from "lodash";

import {
  GOOGLE_ACCOUNT_TYPE,
  GAM_LOGO_TYPE
} from "../../common/constants/common";
import AdManagerImage from "../../../common/images/ad_manager_header_raw.png";
import AdManager360Image from "../../../common/images/ad_manager360_header.png";
import BillingSectionImage from "../../../common/images/gam_billing_section_focus.png";
import AdSensePaymentSetting from "../../../common/images/adsense_payment_setting.png";
import AdSensePaymentProfileCountry from "../../../common/images/adsense_payment_profile_country.png";
import CountrySelector from "../../../subscription-and-billing/components/country-selector";

const PublisherFormSchema = Yup.object().shape({
  gamLogoType: Yup.string()
    .oneOf(_.values(GAM_LOGO_TYPE))
    .required("Please select your GAM logo"),
  hasBillingSection: Yup.boolean().when("gamLogoType", {
    is: GAM_LOGO_TYPE.AD_MANAGER,
    then: Yup.boolean().required(
      "Please check if you have a Billing section in your GAM console"
    ),
    otherwise: Yup.boolean().notRequired()
  }),
  geoCountryId: Yup.number().required("Please select your GAM country"),
  googleAccountType: Yup.number()
    .oneOf(_.values(GOOGLE_ACCOUNT_TYPE))
    .required("Please let us know your GAM account type")
});

function PublisherFormGAMProfile(props) {
  const {
    defaultPublisher,
    handlePreviousStep,
    handleCancel,
    handlePublisherFormSubmit
  } = props;

  const [isCountrySettingHelpOpen, setIsCountrySettingHelpOpen] = useState(
    false
  );

  function handlePublisherGAMProfileFormSubmit(values) {
    // if validation passes
    console.log("publihser gam profile form submit", values);
    handlePublisherFormSubmit(values);
  }

  function handleToggleCountrySettingHelp() {
    setIsCountrySettingHelpOpen(!isCountrySettingHelpOpen);
  }

  function handleGamLogoTypeSelected(gamLogoType, setValues, values) {
    if (gamLogoType === GAM_LOGO_TYPE.AD_MANAGER_360) {
      setValues({
        ...values,
        gamLogoType: gamLogoType,
        googleAccountType: GOOGLE_ACCOUNT_TYPE.AD_MANAGER_360
      });
    } else {
      setValues({ ...values, gamLogoType: gamLogoType });
    }
  }

  function handleHasBillingSectionSelected(
    hasBillingSection,
    setValues,
    values
  ) {
    if (values.gamLogoType === GAM_LOGO_TYPE.AD_MANAGER && hasBillingSection) {
      setValues({
        ...values,
        googleAccountType: GOOGLE_ACCOUNT_TYPE.INVOICED_AD_MANAGER,
        hasBillingSection: hasBillingSection
      });
    } else if (
      values.gamLogoType === GAM_LOGO_TYPE.AD_MANAGER &&
      !hasBillingSection
    ) {
      setValues({
        ...values,
        googleAccountType: GOOGLE_ACCOUNT_TYPE.AD_MANAGER,
        hasBillingSection: hasBillingSection
      });
    } else {
      setValues({
        ...values,
        hasBillingSection: hasBillingSection
      });
    }
  }

  function handleCountrySelect(geoCountryId, setValues, values) {
    setValues({ ...values, geoCountryId: geoCountryId });
  }

  return (
    <React.Fragment>
      <div className="text-muted mb-4" style={{ letterSpacing: "0.04em" }}>
        <b>GOOGLE AD MANAGER (GAM) PROFILE</b>
      </div>

      <Formik
        initialValues={defaultPublisher}
        validationSchema={PublisherFormSchema}
        onSubmit={handlePublisherGAMProfileFormSubmit}
        render={props => {
          const { errors, touched, values } = props;

          return (
            <FormikForm onSubmit={props.handleSubmit}>
              <h4>Inside your GAM console</h4>
              <hr />
              <Form.Row className="mb-4">
                <Form.Group as={Col} controlId="gamLogoType">
                  <Form.Label
                    className="text-dark"
                    style={{ fontSize: "18px", fontWeight: "bolder" }}
                  >
                    Which of the following is your logo on the top left corner
                    of the console?
                  </Form.Label>
                  <Form.Control
                    style={{ display: "none" }}
                    type="string"
                    name="gamLogoType"
                    onChange={props.handleChange}
                    value={values.gamLogoType}
                    isValid={touched.gamLogoType && !errors.gamLogoType}
                    isInvalid={errors.gamLogoType && touched.gamLogoType}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.gamLogoType}
                  </Form.Control.Feedback>
                  <div>
                    <ListGroup>
                      <ListGroup.Item
                        action
                        type="button"
                        onClick={() =>
                          handleGamLogoTypeSelected(
                            GAM_LOGO_TYPE.AD_MANAGER,
                            props.setValues,
                            values
                          )
                        }
                      >
                        <div className="d-flex">
                          <div
                            className="m-2 mr-3 justify-content-start align-self-center"
                            style={{ width: "32px" }}
                          >
                            {values.gamLogoType ===
                              GAM_LOGO_TYPE.AD_MANAGER && (
                              <i className="fa fa-check" />
                            )}
                          </div>
                          <img src={AdManagerImage} width="480px" />
                        </div>
                      </ListGroup.Item>
                      <ListGroup.Item
                        action
                        type="button"
                        onClick={() =>
                          handleGamLogoTypeSelected(
                            GAM_LOGO_TYPE.AD_MANAGER_360,
                            props.setValues,
                            values
                          )
                        }
                      >
                        <div className="d-flex">
                          <div
                            className="justify-content-start align-self-center m-2 mr-3"
                            style={{ width: "32px" }}
                          >
                            {values.gamLogoType ===
                              GAM_LOGO_TYPE.AD_MANAGER_360 && (
                              <i className="fa fa-check" />
                            )}
                          </div>
                          <img src={AdManager360Image} width="480px" />
                        </div>
                      </ListGroup.Item>
                    </ListGroup>
                  </div>

                  {values.gamLogoType === GAM_LOGO_TYPE.AD_MANAGER && (
                    <div style={{ marginTop: "48px" }}>
                      <Form.Label
                        className="text-dark"
                        style={{ fontSize: "18px", fontWeight: "bolder" }}
                      >
                        Do you see a <b>"Billing"</b> section in the left
                        sidebar?
                      </Form.Label>
                      <Form.Control
                        style={{ display: "none" }}
                        name="hasBillingSection"
                        onChange={props.handleChange}
                        value={values.hasBillingSection}
                        isValid={
                          touched.hasBillingSection && !errors.hasBillingSection
                        }
                        isInvalid={
                          errors.hasBillingSection && touched.hasBillingSection
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.hasBillingSection}
                      </Form.Control.Feedback>
                      <div className="d-flex">
                        <img
                          src={BillingSectionImage}
                          height="320px"
                          className="mr-4"
                        />

                        <div className="flex-fill">
                          <ListGroup>
                            <ListGroup.Item
                              action
                              type="button"
                              className="d-flex"
                              onClick={() =>
                                handleHasBillingSectionSelected(
                                  true,
                                  props.setValues,
                                  values
                                )
                              }
                            >
                              <div
                                className=" mr-3 justify-content-start align-self-center"
                                style={{ width: "32px" }}
                              >
                                {values.hasBillingSection && (
                                  <i className="fa fa-check" />
                                )}
                              </div>
                              <div className=" align-self-center">
                                Yes{" "}
                                <span className="text-muted">
                                  (Invoiced Ad Manager)
                                </span>
                              </div>
                            </ListGroup.Item>
                            <ListGroup.Item
                              action
                              type="button"
                              className="d-flex"
                              onClick={() =>
                                handleHasBillingSectionSelected(
                                  false,
                                  props.setValues,
                                  values
                                )
                              }
                            >
                              <div
                                className=" mr-3 justify-content-start align-self-center"
                                style={{ width: "32px" }}
                              >
                                {values.hasBillingSection === false && (
                                  <i className="fa fa-check" />
                                )}
                              </div>
                              <div className=" align-self-center">
                                No{" "}
                                <span className="text-muted">
                                  (Basic Ad Manager)
                                </span>
                              </div>
                            </ListGroup.Item>
                          </ListGroup>
                        </div>
                      </div>
                    </div>
                  )}
                </Form.Group>
              </Form.Row>

              {values.googleAccountType !== "" && (
                <React.Fragment>
                  <h4>Inside your AdSense console</h4>
                  <hr />
                  <Form.Row className="mb-4">
                    <Form.Group
                      as={Col}
                      lg="8"
                      controlId="validationFormikGoogleAccountCountry"
                    >
                      <Form.Label
                        className="text-dark"
                        style={{ fontSize: "18px", fontWeight: "bolder" }}
                      >
                        What is your country set for payment?
                      </Form.Label>
                      <Form.Control
                        style={{ display: "none" }}
                        type="number"
                        name="geoCountryId"
                        onChange={props.handleChange}
                        value={values.geoCountryId}
                        isValid={touched.geoCountryId && !errors.geoCountryId}
                        isInvalid={errors.geoCountryId && touched.geoCountryId}
                      />
                      <CountrySelector
                        geoCountryId={values.geoCountryId}
                        handleChange={geoCountryId =>
                          handleCountrySelect(
                            geoCountryId,
                            props.setValues,
                            values
                          )
                        }
                      />
                      <Button
                        variant="link"
                        size="sm"
                        className="p-0 m-0"
                        onClick={handleToggleCountrySettingHelp}
                      >
                        Not sure which country?{" "}
                        <i
                          className={
                            isCountrySettingHelpOpen
                              ? "fa fa-angle-up"
                              : "fa fa-angle-down"
                          }
                        />
                      </Button>

                      <Form.Control.Feedback type="invalid">
                        {errors.geoCountryId}
                      </Form.Control.Feedback>
                    </Form.Group>

                    {isCountrySettingHelpOpen && (
                      <Card>
                        <Card.Body>
                          {/* <p>You can check your Google AdSense account configuration: </p> */}
                          <p>1. Login to your Google AdSense console </p>
                          <p>
                            2. Click on <b>"Payments"</b> on the left panel{" "}
                          </p>
                          <p>
                            3. Under the <b>"Settings"</b> section, click on{" "}
                            <b>"MANAGE SETTINGS"</b>
                          </p>
                          <img
                            src={AdSensePaymentSetting}
                            width="640px"
                            className="mb-4"
                          />
                          <p>
                            4. Under <b>"Payments profile"</b>, check your{" "}
                            <b>"Country/Region"</b>
                          </p>
                          <img
                            src={AdSensePaymentProfileCountry}
                            width="640px"
                            className="mb-4"
                          />
                        </Card.Body>
                      </Card>
                    )}
                  </Form.Row>
                </React.Fragment>
              )}
              <div className="d-flex justify-content-end mt-4">
                <Button variant="link" type="button" onClick={handleCancel}>
                  Cancel
                </Button>
                <Button
                  variant="secondary"
                  type="button"
                  className="ml-2"
                  style={{ paddingLeft: "24px", paddingRight: "24px" }}
                  onClick={() => handlePreviousStep(values)}
                >
                  Previous
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                  className="ml-2"
                  style={{ paddingLeft: "24px", paddingRight: "24px" }}
                >
                  Create Publisher
                </Button>
              </div>
            </FormikForm>
          );
        }}
      />
    </React.Fragment>
  );
}

export default PublisherFormGAMProfile;
