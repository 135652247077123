import React from "react";

import ItemStyle from "common/styles/inventory-item.css";
import NumberFormat from "../../_newapp/home-dashboard/components/number-format";

const YbReqStatDiagram = ({ item, isGraphOnly }) => {
  const { gptReqPercentage, ampReqPercentage, dailyCompatibleAvgReq } = item;

  if (item.hasChildren && !item.isCompatible) {
    return "";
  }

  return (
    <div>
      {!isGraphOnly && (
        <div className={!item.isCompatible ? ItemStyle.notCompatibleText : ""}>
          {item.isCompatible ? (
            <NumberFormat
              value={dailyCompatibleAvgReq}
              format="0,0a"
            ></NumberFormat>
          ) : (
            "Not compatible"
          )}
        </div>
      )}

      <div className={ItemStyle.statBarWrapper}>
        {item.isCompatible && !isGraphOnly ? (
          <div
            className={ItemStyle.statIndicator}
            style={{ left: `${gptReqPercentage + ampReqPercentage}%` }}
          ></div>
        ) : (
          ""
        )}

        <div
          className={ItemStyle.gptBar}
          style={{ width: `${gptReqPercentage}%` }}
        ></div>

        <div
          className={ItemStyle.ampBar}
          style={{
            left: `${gptReqPercentage}%`,
            width: `${ampReqPercentage}%`,
          }}
        ></div>
      </div>
    </div>
  );
};

module.exports = YbReqStatDiagram;
