import React from "react";
import { Button, Form, Col } from "react-bootstrap";
import { Formik, Form as FormikForm, FieldArray } from "formik";
import * as Yup from "yup";

const PublisherFormSchema = Yup.object().shape({
  publisherName: Yup.string().required(
    "Please fill in your publisher name (Usually it's the company name)"
  ),
  websiteUrls: Yup.array().of(
    Yup.string().url(
      "Please fill in a URL that starts with http:// or https://"
    )
  )
});

function PublisherFormBasic(props) {
  const { defaultPublisher, handleNextStep, handleCancel } = props;

  function handlePublisherFormBasicSubmit(values) {
    // if validation passes
    console.log("publihser basic form submit", values);
    handleNextStep(values);
  }

  return (
    <React.Fragment>
      <div className="text-muted mb-4" style={{ letterSpacing: "0.04em" }}>
        <b>BASIC INFORMATION</b>
      </div>

      <Formik
        initialValues={defaultPublisher}
        validationSchema={PublisherFormSchema}
        onSubmit={handlePublisherFormBasicSubmit}
        render={props => {
          const { errors, touched, values } = props;

          return (
            <FormikForm onSubmit={props.handleSubmit}>
              <Form.Row className="mb-4">
                <Form.Group as={Col} lg="10" controlId="publisherName">
                  <Form.Label
                    className="text-dark"
                    style={{ fontSize: "18px", fontWeight: "bolder" }}
                  >
                    Publisher Name
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="publisherName"
                    onChange={props.handleChange}
                    value={values.publisherName}
                    isValid={touched.publisherName && !errors.publisherName}
                    isInvalid={errors.publisherName && touched.publisherName}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.publisherName}
                  </Form.Control.Feedback>
                </Form.Group>
              </Form.Row>

              <Form.Row className="mb-4">
                <Form.Group as={Col} controlId="websiteUrls">
                  <Form.Label
                    className="text-dark mb-0"
                    style={{ fontSize: "18px", fontWeight: "bolder" }}
                  >
                    Website Domain{" "}
                    <small className="text-info">(optional)</small>
                  </Form.Label>
                  <Form.Text
                    className="text-secondary mb-2"
                    style={{ fontSize: "14px" }}
                  >
                    {/* <small> */}
                    Website domains that are currently (or planning on) serving
                    ads - this info helps us better understand your inventory
                    and finding it's true value.
                    {/* </small> */}
                  </Form.Text>

                  <FieldArray
                    name="websiteUrls"
                    render={arrayHelpers => {
                      return (
                        <React.Fragment>
                          {values.websiteUrls.map((url, index) => {
                            return (
                              <div key={index} className="mt-1">
                                <Form.Control
                                  // as={Field}
                                  name={`websiteUrls[${index}]`}
                                  onChange={props.handleChange}
                                  value={values.websiteUrls[index]}
                                  isValid={
                                    // touched.websiteUrls &&
                                    errors.websiteUrls &&
                                    // touched.websiteUrls[index] &&
                                    !errors.websiteUrls[index]
                                  }
                                  isInvalid={
                                    // touched.websiteUrls &&
                                    errors.websiteUrls &&
                                    errors.websiteUrls[index]
                                    // &&
                                    // touched.websiteUrls[index]
                                  }
                                />
                                <Form.Control.Feedback type="valid" />
                                <Form.Control.Feedback type="invalid">
                                  {errors.websiteUrls &&
                                    errors.websiteUrls[index]}
                                </Form.Control.Feedback>
                                {/* <Field name={`websiteUrls.${index}`} />
                                      <button
                                        type="button"
                                        disabled={!url}
                                        onClick={() =>
                                          arrayHelpers.remove(index)
                                        }
                                      >
                                        -
                                      </button> */}
                              </div>
                            );
                          })}

                          <div>
                            {values.websiteUrls[
                              values.websiteUrls.length - 1
                            ] !== "" &&
                              !errors.websiteUrls && (
                                <Button
                                  variant="light"
                                  type="button"
                                  size="sm"
                                  className="mt-1"
                                  style={{ backgroundColor: "#E2E6E9" }}
                                  disabled={
                                    values.websiteUrls[
                                      values.websiteUrls.length - 1
                                    ] === "" || errors.websiteUrls
                                  }
                                  onClick={() => arrayHelpers.push("")}
                                >
                                  Add another url
                                </Button>
                              )}
                          </div>
                        </React.Fragment>
                      );
                    }}
                  />
                </Form.Group>
              </Form.Row>

              <div className="d-flex justify-content-end mt-4">
                <Button variant="link" type="button" onClick={handleCancel}>
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                  className="ml-2"
                  style={{ paddingLeft: "24px", paddingRight: "24px" }}
                >
                  Next Step
                </Button>
              </div>
            </FormikForm>
          );
        }}
      />
    </React.Fragment>
  );
}

export default PublisherFormBasic;
