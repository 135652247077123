import React from "react";
import { Query, withApollo, Mutation } from "react-apollo";
import { Redirect } from "react-router-dom";

import CommonStyle from "common/styles/common.css";
import Styles from "../../summary/styles/summary.css";
import FormStyle from "common/styles/forms.css";
import Constants from "common/constants/constants";
import YbModal from "common/components/yb-modal";
import YbLoading from "common/components/yb-loading";
import YbLoadingView from "common/components/yb-loading-view";
import YbPagedTableView from "common/components/yb-paged-table-view";
import YbMultipleSelectOnboardTable from "common/components/yb-multiple-select-onboard-table";
import YbTooltip from "common/components/yb-tooltip";
import { ACCOUNT_CONFIG } from "common/query/publisher";
import YbHighlight from "common/components/yb-highlight";
import UserStyles from "../../user/styles/signup.css";

import YbProblems from "../../summary/components/yb-problems";

import { CacheHelper } from "../services/cache-helper";
import OAuth from "../components/oauth";
import {
  query as adsenseQuery,
  mutation as adsenseMutation,
  typenames as adsenseTypeNames,
  authorize as AUTHORIZE_ADSENSE,
} from "../query/adsense-account";
import {
  query as adxQuery,
  mutation as adxMutation,
  typenames as adxTypeNames,
} from "../query/adx-account";
import { UPDATE_NETWORK, QUERY_NETWORK } from "../query/networks";
import { GET_GAM_ADX_ACCOUNTS } from "../query/gam-adx-accounts";

const minCostCPM = 0.0;
const maxCostCPM = 99999999.9999;
const costCPMDecimals = 4;
const minFreeQuota = 0;
const maxFreeQuota = 9007199254740991;

class NetworkAccounts extends React.Component {
  constructor(props) {
    super(props);

    window.gtagPage("/network-config");

    const { client, networkId, publisherId } = props;
    this.createHelper(client, networkId, publisherId);

    let overviewRoute = "/summary/overview";
    if (publisherId > 0) {
      overviewRoute = `/publisher/${publisherId}/summary/overview`;
    }

    this.state = {
      selectedOrders: [],
      selectedCandidates: [],
      showAdxModal: false,
      multiplier: "",
      freeQuota: "0",
      costCPM: minCostCPM.toFixed(costCPMDecimals),
      publisherId: _.parseInt(publisherId),
      overviewRoute: overviewRoute,
      submitting: false,
      adxAccount: {},
    };
    this.count = props.count || 5;
  }

  createHelper(client, networkId, publisherId) {
    this.adsenseHelper = new CacheHelper(
      client,
      {
        query: adsenseQuery,
        variables: {
          networkId: networkId,
          pubId: publisherId,
        },
      },
      "adsenseAccounts",
      adsenseTypeNames
    );
    this.adxHelper = new CacheHelper(
      client,
      {
        query: adxQuery,
        variables: {
          networkId: networkId,
          pubId: publisherId,
        },
      },
      "adxAccounts",
      adxTypeNames
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.networkId !== prevProps.networkId) {
      const { client, networkId, publisherId } = props;
      this.createHelper(client, networkId, publisherId);
    }
  }

  costCPMChange(event) {
    this.setState({
      costCPM: event.target.value,
    });
  }

  freeQuotaChange(event) {
    this.setState({
      freeQuota: parseInt(event.target.value),
    });
  }

  costCPMOnBlur(event) {
    var value = parseFloat(event.target.value);

    if (value < minCostCPM || !value) {
      value = minCostCPM;
    }

    if (value > maxCostCPM) {
      value = maxCostCPM;
    }

    this.setState({
      costCPM: value.toFixed(costCPMDecimals),
    });
  }

  freeQuotaOnBlur(event) {
    var value = parseInt(event.target.value);

    if (!value) {
      value = 0;
    }

    if (value < minFreeQuota || !value) {
      value = minFreeQuota;
    }

    if (value > maxFreeQuota) {
      value = maxFreeQuota;
    }

    this.setState({
      freeQuota: value.toString(),
    });
  }

  setNetwork(network) {
    if (!this.state.network) {
      this.state.network = network;
      this.state.costCPM = parseFloat(network.adServingCost.costCpm).toFixed(
        costCPMDecimals
      );
      this.state.freeQuota = network.adServingCost.freeQuota;
    }
  }

  backToSummary() {
    this.props.history.goBack();
  }

  renderNetworkProps(accountConfig) {
    var networkId = this.props.networkId;
    var network = null;

    if (!accountConfig.gamNetwork.estAdServingCostSync.enabled) {
      return <span />;
    }

    return (
      <Query
        query={QUERY_NETWORK}
        variables={{ pubId: this.state.publisherId }}
        fetchPolicy={"network-only"}
      >
        {({ loading, error, data }) => {
          if (loading) {
            return <YbLoadingView />;
          }

          if (error) {
            return <Redirect to={this.state.overviewRoute} />;
          }

          if (data) {
            network = data.gamNetworks.edges.find(
              (edge) => edge.node.networkId === networkId
            ).node;
            this.setNetwork(network);
          }

          return (
            <Mutation mutation={UPDATE_NETWORK}>
              {(updateNetwork, { data, loading, called, error }) => {
                var saveBtn = (
                  <button className={FormStyle.submitBtn} type="submit">
                    <span>
                      <i className="fa fa-save" /> {i18n`SAVE`}
                    </span>
                  </button>
                );

                if (loading) {
                  saveBtn = (
                    <span className={FormStyle.submitLoading}>
                      <YbLoading />
                    </span>
                  );
                }

                if (error) {
                  saveBtn = (
                    <span className={FormStyle.submitLoading}>
                      <span className="text-danger">
                        {i18n`ERROR_UPDATING_NETWORK_ELLIPSIS`}
                      </span>
                    </span>
                  );
                }

                if (data) {
                  return <Redirect to={this.state.overviewRoute} />;
                }

                return (
                  <div>
                    <div>
                      <div className="row">
                        <div className="col">
                          <div className={CommonStyle.pageTitle}>
                            {network.name}
                          </div>
                        </div>
                      </div>
                      <form
                        className={FormStyle.form}
                        onSubmit={(event) => {
                          var costCPM = this.state.costCPM;
                          var freeQuota = this.state.freeQuota;
                          var mutationParams = {
                            variables: {
                              input: {
                                networkId: networkId,
                                adServingCost: {
                                  costCpm: parseFloat(costCPM),
                                  freeQuota: parseInt(freeQuota),
                                },
                              },
                              pubId: this.state.publisherId,
                            },
                          };

                          updateNetwork(mutationParams);
                          event.preventDefault();
                        }}
                      >
                        <div className={FormStyle.row}>
                          <div className={FormStyle.label}>
                            {i18n`AD_SERVING_COST_PARENTHESIS_CPM`}{" "}
                            <span className={FormStyle.requiredStar}>*</span>
                          </div>
                          <div className={FormStyle.currencyWrapper}>
                            <span className={FormStyle.currency}>$</span>
                            <input
                              required
                              type="number"
                              step="0.0001"
                              min={minCostCPM}
                              max={maxCostCPM}
                              value={this.state.costCPM}
                              onChange={this.costCPMChange.bind(this)}
                              onBlur={this.costCPMOnBlur.bind(this)}
                            />
                          </div>
                        </div>
                        <div className={FormStyle.row}>
                          <div className={FormStyle.label}>
                            {i18n`FREE_QUOTA`}{" "}
                            <span className={FormStyle.requiredStar}>*</span>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <input
                                required
                                type="number"
                                min={minFreeQuota}
                                max={maxFreeQuota}
                                value={this.state.freeQuota}
                                onChange={this.freeQuotaChange.bind(this)}
                                onBlur={this.freeQuotaOnBlur.bind(this)}
                              />
                            </div>
                          </div>
                        </div>
                        {saveBtn}
                      </form>
                      <button
                        className={FormStyle.cancelBtn}
                        onClick={this.backToSummary.bind(this)}
                      >
                        <i className="fa fa-times" /> {i18n`CANCEL`}
                      </button>
                    </div>
                    <br />
                    <br />
                    <br />
                  </div>
                );
              }}
            </Mutation>
          );
        }}
      </Query>
    );
  }

  renderAdSenseAccounts(accountConfig) {
    const { count } = this;
    var adSenseSubmited = this.state.adSenseSubmited;
    var networkId = this.props.networkId;

    return (
      <Query
        variables={{ first: 1000, networkId, pubId: this.state.publisherId }}
        query={adsenseQuery}
        fetchPolicy="network-only"
      >
        {({ loading, error, data, fetchMore, refetch }) => {
          if (error) {
            return <h1>{error.message}</h1>;
          }

          if (loading) {
            return <YbLoading />;
          }

          return (
            <div className={FormStyle.row}>
              <YbPagedTableView
                {...this.createAdsenseConfig(
                  data.adsenseAccounts,
                  accountConfig
                )}
                onUpdateCursor={(cursor) =>
                  fetchMore({
                    variables: {
                      first: count,
                      after: cursor,
                      networkId,
                      pubId: this.state.publisherId,
                    },
                    updateQuery: this.adsenseHelper.afterFetchMore.bind(
                      this.adsenseHelper
                    ),
                  })
                }
              >
                {accountConfig.gamNetwork.adsense.enabled ? (
                  <Mutation mutation={adsenseMutation}>
                    {(addAdsenseAccount, { data, loading, called, error }) => (
                      <OAuth
                        {...this.oauthConfig()}
                        modal={{
                          error: adSenseSubmited && error,
                          disabled: loading,
                          title: i18n`NEW_ADSENSE_ACCOUNT`,
                          fields: [
                            {
                              key: "name",
                              placeholder: `${i18n`EX_DOT`} ${i18n`ACCOUNT_DISPLAY_NAME_ELLIPSIS`}`,
                              title: i18n`DISPLAY_NAME`,
                            },
                            {
                              key: "clientCode",
                              title: i18n`WEB_PROPERTY_CODE`,
                              placeholder: `${i18n`EX_DOT`} pub-12345678999999...`,
                            },
                          ],
                          closeCallback: () => {
                            this.setState({
                              adSenseSubmited: false,
                            });
                          },
                          onSubmit: ({ name, clientCode, code }) => {
                            this.setState({
                              adSenseSubmited: true,
                            });

                            return addAdsenseAccount({
                              variables: {
                                networkId,
                                clientCode,
                                name,
                                authorizationCode: code,
                                pubId: this.state.publisherId,
                              },
                            }).then(({ data }) => {
                              this.setState({
                                adSenseSubmited: false,
                              });

                              setTimeout(refetch, 0);
                            });
                          },
                        }}
                      >
                        <YbTooltip
                          message={i18n`ADD_ADSENSE_ACCOUNT`}
                          position="left"
                        >
                          <button
                            key="add"
                            type="button"
                            className={CommonStyle.buttonSuccess}
                          >
                            <i className="fa fa-plus" />
                          </button>
                        </YbTooltip>
                      </OAuth>
                    )}
                  </Mutation>
                ) : (
                  <button
                    key="add"
                    type="button"
                    className={CommonStyle.buttonSuccess}
                    disabled
                  >
                    <i className="fa fa-plus" />
                  </button>
                )}
              </YbPagedTableView>
            </div>
          );
        }}
      </Query>
    );
  }

  renderAddAdxAccountModal(
    networkId,
    adxAccount,
    submitting,
    addAdxAccount,
    mutationProps,
    refetch
  ) {
    var reloadExternalSource = this.state.reloadExternalSource;
    var variables = {
      pcFilter: {
        networkId: networkId,
      },
      adxFilter: {
        networkId: networkId,
      },
      pubId: this.props.publisherId,
    };

    return (
      <Query
        query={GET_GAM_ADX_ACCOUNTS}
        variables={variables}
        fetchPolicy={"network-only"}
      >
        {({ loading, error, data, refetch: refetchAccounts }) => {
          let existingAccounts = _.get(data, "adxAccounts.edges", []).map(
            (edge) => {
              const code = _.get(edge, "node.clientCode");
              return code;
            }
          );

          let extAccounts = _.get(data, "extPropertyCodes", []).map((edge) => {
            const code = _.get(edge, "code");
            return code;
          });

          let candidates = extAccounts.filter((extAccount) => {
            return existingAccounts.indexOf(extAccount) === -1;
          });

          candidates = _.map(candidates, (c) => {
            return {
              name: c,
              value: c,
            };
          });

          if (data && candidates.length > 0) {
            return (
              <YbModal
                error={mutationProps.error}
                onHide={(_) => this.setState({ showAdxModal: false })}
                show={this.state.showAdxModal}
                fields={[
                  {
                    key: "name",
                    placeholder: `${i18n`EX_DOT`} ${i18n`ACCOUNT_DISPLAY_NAME_ELLIPSIS`}`,
                    title: i18n`DISPLAY_NAME`,
                    extra: (
                      <span>
                        <YbTooltip
                          big={true}
                          position="auto"
                          message={
                            <div>
                              <YbHighlight
                                text={i18n`On your Ad Manager Console, look up the Display name of your account under Admin > Linked accounts > Ad Exchange`}
                                highlight={[
                                  i18n`Display name`,
                                  i18n`Admin`,
                                  i18n`Linked accounts`,
                                  i18n`Ad Exchange`,
                                  i18n`Ad Manager Console`,
                                ]}
                              />
                            </div>
                          }
                        >
                          <span
                            className={`${CommonStyle.buttonLinkLight} ${UserStyles.infoTooltip}`}
                          >
                            <i className="fa fa-info-circle" />
                          </span>
                        </YbTooltip>
                      </span>
                    ),
                  },
                  {
                    key: "clientCode",
                    title: i18n`WEB_PROPERTY_CODE`,
                    placeholder: `${i18n`EX_DOT`} ca-pub-12345678999999...`,
                    type: "select",
                    selectOptions: candidates,
                    extra: (
                      <span>
                        <YbTooltip
                          big={true}
                          position="auto"
                          message={
                            <div>
                              <YbHighlight
                                text={i18n`On your Ad Manager Console, look up the Web Property code of your account under Admin > Linked accounts > Ad Exchange`}
                                highlight={[
                                  i18n`Web Property code`,
                                  i18n`Admin`,
                                  i18n`Linked accounts`,
                                  i18n`Ad Exchange`,
                                  i18n`Ad Manager Console`,
                                ]}
                              />
                            </div>
                          }
                        >
                          <span
                            className={`${CommonStyle.buttonLinkLight} ${UserStyles.infoTooltip}`}
                          >
                            <i className="fa fa-info-circle" />
                          </span>
                        </YbTooltip>
                      </span>
                    ),
                  },
                ]}
                disabled={mutationProps.loading}
                onSubmit={({ name, clientCode }) => {
                  addAdxAccount({
                    variables: {
                      networkId,
                      clientCode,
                      name,
                      pubId: this.state.publisherId,
                    },
                  }).then(({ data }) => {
                    this.setState({ showAdxModal: false });
                    setTimeout(() => {
                      refetch();
                      refetchAccounts();
                    });
                  });
                }}
                title={i18n`NEW_ADX_ACCOUNT`}
              />
            );
          } else {
            return (
              <YbModal
                error={mutationProps.error}
                onHide={(_) => this.setState({ showAdxModal: false })}
                show={this.state.showAdxModal}
                disabled={mutationProps.loading}
                title={i18n`NEW_ADX_ACCOUNT`}
                successText={i18n`ADD_ADX_ACCOUNT`}
                disableSubmit={submitting || !adxAccount.webPropertyCode}
                disabled={submitting || reloadExternalSource}
              >
                <span className={CommonStyle.textView}>
                  <div
                    className={`${CommonStyle.textAlignCenter} ${CommonStyle.textAlignCenter} ${Styles.inModal}`}
                  >
                    {loading && <YbLoading isWhiteText={true} />}

                    {error && (
                      <div className={Styles.networkCandidate}>
                        <div
                          className={`${Styles.titleWrapper} ${Styles.errorMsg} text-danger`}
                        >
                          Error loading your AdX web property codes. Please make
                          sure your account is linked to a AdX account, or
                          contact us for more info.
                        </div>
                      </div>
                    )}

                    {data && candidates.length === 0 && (
                      <div
                        style={{ paddingTop: "60px", paddingBottom: "200px" }}
                      >
                        No linked AdX accounts were found in your Ad Manager.
                        <span>
                          <YbTooltip
                            position="bottom"
                            message={
                              <div>
                                <YbHighlight
                                  text={i18n`On your Ad Manager Console, look up the Web Property code of your account under Admin > Linked accounts > Ad Exchange`}
                                  highlight={[
                                    i18n`Web Property code`,
                                    i18n`Admin`,
                                    i18n`Linked accounts`,
                                    i18n`Ad Exchange`,
                                    i18n`Ad Manager Console`,
                                  ]}
                                />
                              </div>
                            }
                          >
                            <span
                              className={`${CommonStyle.buttonLinkLight} ${UserStyles.infoTooltip}`}
                            >
                              <i className="fa fa-info-circle" />
                            </span>
                          </YbTooltip>
                        </span>
                      </div>
                    )}
                  </div>
                </span>
              </YbModal>
            );
          }
        }}
      </Query>
    );
  }

  renderAdXAccounts(accountConfig) {
    const { count } = this;
    var networkId = this.props.networkId;

    var adxAccount = this.state.adxAccount;
    var submitting = this.state.submitting;
    var reloadExternalSource = this.state.reloadExternalSource;

    return (
      <Query
        variables={{ first: 1000, networkId, pubId: this.state.publisherId }}
        query={adxQuery}
        fetchPolicy="network-only"
      >
        {({ loading, error, data, fetchMore, refetch }) => {
          if (error) {
            return <h1>{error.message}</h1>;
          }

          if (loading) {
            return <YbLoading />;
          }

          return (
            <div className={FormStyle.row}>
              <YbPagedTableView
                {...this.createAdxConfig(data.adxAccounts)}
                onUpdateCursor={(cursor) =>
                  fetchMore({
                    variables: {
                      first: count,
                      after: cursor,
                      networkId,
                      pubId: this.state.publisherId,
                    },
                    updateQuery: this.adxHelper.afterFetchMore.bind(
                      this.adxHelper
                    ),
                  })
                }
              >
                {accountConfig.gamNetwork.adx.enabled ? (
                  <YbTooltip message={i18n`ADD_ADX_ACCOUNT`} position="left">
                    <button
                      onClick={(_) => this.setState({ showAdxModal: true })}
                      key="add"
                      type="button"
                      className={CommonStyle.buttonSuccess}
                    >
                      <i className="fa fa-plus" />
                    </button>
                  </YbTooltip>
                ) : (
                  <button
                    disabled
                    key="add"
                    type="button"
                    className={CommonStyle.buttonSuccess}
                  >
                    <i className="fa fa-plus" />
                  </button>
                )}
                <Mutation mutation={adxMutation}>
                  {(addAdxAccount, { data, loading, called, error }) =>
                    // 1. Check if user has any web property codes
                    // 2. if not -> show you dont have any codes
                    // 3. if yes -> show form

                    {
                      const mutationProps = { data, loading, called, error };
                      if (this.state.showAdxModal) {
                        return this.renderAddAdxAccountModal(
                          networkId,
                          adxAccount,
                          submitting,
                          addAdxAccount,
                          mutationProps,
                          refetch
                        );
                      }
                      return "";
                    }
                  }
                </Mutation>
              </YbPagedTableView>
            </div>
          );
        }}
      </Query>
    );
  }

  render() {
    const { count } = this;
    const { networkId } = this.props;
    var accountConfig = null;
    var adSenseSubmited = this.state.adSenseSubmited;
    var orderParams = {
      extFilter: {
        gamNetworkId: networkId,
      },
      filter: {
        networkId: networkId,
      },
    };

    return (
      <Query
        query={ACCOUNT_CONFIG}
        variables={{ pubId: this.state.publisherId }}
        fetchPolicy="network-only"
      >
        {({ loading, error, data }) => {
          if (loading) {
            return <YbLoadingView />;
          }

          if (error) {
            return (
              <div className={`${CommonStyle.wrapper}`}>
                <div
                  className={`${CommonStyle.textDanger} ${CommonStyle.textView}`}
                >
                  {i18n`ERROR_LOADING_ACCOUNT_CONFIG_ELLIPSIS`}
                </div>
              </div>
            );
          }

          if (data && !accountConfig) {
            accountConfig = data.accountConfig.config.resourceConfig;
          }

          return (
            <div className={CommonStyle.wrapper}>
              <div className="row">
                <div className="col">
                  {this.renderNetworkProps(accountConfig)}
                  {/* {this.renderAdSenseAccounts(accountConfig)} */}
                  {this.renderAdXAccounts(accountConfig)}
                </div>
              </div>
            </div>
          );
        }}
      </Query>
    );
  }

  orderSearchValue(order) {
    if (!order.extGamBenchmarkOrder) {
      return "";
    }

    return `${order.extGamBenchmarkOrder.name} ${order.extGamBenchmarkOrder.extGamBenchmarkOrderId}`;
  }

  candidateSearchValue(candidate) {
    return `${candidate.name} ${candidate.extGamBenchmarkOrderId}`;
  }

  candidatesChange(candidatesIds) {
    this.setState({
      selectedCandidates: candidatesIds,
    });
  }

  fixOrderData(data) {
    var alreadyUsed = [];
    data.gamBenchmarkOrders.edges.forEach((order, index) => {
      var external = data.extGamBenchmarkOrders.find((extOrder) => {
        return (
          extOrder.extGamBenchmarkOrderId === order.node.extGamBenchmarkOrderId
        );
      });

      if (external) {
        order.node.extGamBenchmarkOrder = external;
        alreadyUsed.push(external.extGamBenchmarkOrderId);
      }

      return order;
    });

    data.candidates = data.extGamBenchmarkOrders.filter((candidate) => {
      return alreadyUsed.indexOf(candidate.extGamBenchmarkOrderId) === -1;
    });

    return data;
  }

  oauthConfig() {
    return {
      url: "https://accounts.google.com/o/oauth2/v2/auth",
      params: {
        scope: "openid email https://www.googleapis.com/auth/adsense",
        include_granted_scopes: true,
        redirect_uri: window.location.origin,
        response_type: "code",
        client_id: process.env.GOOGLE_SERVICE_CLIENT_ID,
        prompt: "consent",
        access_type: "offline",
      },
    };
  }

  renderAuthorizeBtn(accountId, refetch, accountConfig) {
    if (!accountConfig.gamNetwork.adsense.enabled) {
      return (
        <span className={`${CommonStyle.button}`} disabled>
          <i className="fa fa-link" />
        </span>
      );
    }

    return (
      <Mutation mutation={AUTHORIZE_ADSENSE}>
        {(authorizeAdSense, { data, loading, called, error }) => {
          var mismatch = false;

          if (error) {
            if (error.message.match("Client code mismatched")) {
              mismatch = error.message.replace("GraphQL error:", "");
            }
          }

          if (data) {
            refetch();
          }

          return (
            <OAuth
              {...this.oauthConfig()}
              callback={(code) => {
                authorizeAdSense({
                  variables: {
                    accountId: accountId,
                    authorizationCode: code,
                    pubId: this.state.publisherId,
                  },
                });
              }}
            >
              <span>
                <YbTooltip message={i18n`REAUTHORIZED_ACCOUNT`} position="top">
                  <span className={`${CommonStyle.button}`}>
                    <i className="fa fa-link" />
                  </span>
                </YbTooltip>
                {error && (
                  <span>
                    {mismatch ? (
                      <span>{mismatch}</span>
                    ) : (
                      <span
                        className={CommonStyle.textDanger}
                      >{i18n`ERROR_AUTHORIZING_ACCOUNT_ELLIPSIS`}</span>
                    )}
                  </span>
                )}
              </span>
            </OAuth>
          );
        }}
      </Mutation>
    );
  }

  createAdsenseConfig(
    accounts = { pageInfo: {}, edges: [] },
    accountConfig,
    refetch
  ) {
    const { count } = this;

    return {
      count,
      hasMore: accounts.pageInfo.hasNextPage,
      intowowTag: "adsense",
      title: i18n`ADSENSE_ACCOUNTS`,
      columns: [
        {
          placeholder: `${i18n`EX_DOT`} ${i18n`ACCOUNT_DISPLAY_NAME_ELLIPSIS`}`,
          name: i18n`DISPLAY_NAME`,
          key: "name",
        },
        {
          name: i18n`WEB_PROPERTY_CODE`,
          placeholder: `${i18n`EX_DOT`} pub-12345678999999...`,
          key: "clientCode",
        },
      ],
      rows: accounts.edges.map((account) => {
        account = account.node;
        var unauthorized = account.problems.find(
          (problem) => problem === "UNAUTHORIZED"
        );

        return {
          id: account.accountId.toString(),
          data: {
            name: (
              <span>
                {account.name}
                <YbProblems item={account} />
                {unauthorized &&
                  this.renderAuthorizeBtn(
                    account.accountId,
                    refetch,
                    accountConfig
                  )}
              </span>
            ),
            clientCode: account.clientCode,
          },
        };
      }),
    };
  }

  createAdxConfig(accounts = { pageInfo: {}, edges: [] }) {
    const { count } = this;
    return {
      count,
      hasMore: accounts.pageInfo.hasNextPage,
      intowowTag: "adx",
      title: i18n`ADX_ACCOUNTS`,
      columns: [
        {
          placeholder: `${i18n`EX_DOT`} ${i18n`ACCOUNT_DISPLAY_NAME_ELLIPSIS`}`,
          name: i18n`DISPLAY_NAME`,
          key: "name",
        },
        {
          name: i18n`WEB_PROPERTY_CODE`,
          placeholder: `${i18n`EX_DOT`} ca-pub-12345678999999...`,
          key: "clientCode",
        },
      ],
      rows: accounts.edges.map((account, index) => {
        account = account.node;

        return {
          key: index,
          id: account.accountId.toString(),
          data: {
            name: (
              <span>
                {account.name}
                <YbProblems item={account} />
              </span>
            ),
            clientCode: account.clientCode,
          },
        };
      }),
    };
  }
}

export default withApollo(NetworkAccounts);
