export function formatCandidates(candidates, onboardedExtUnitIds, rootUnit) {
  if (!candidates || candidates.length === 0) {
    return [];
  }

  const unitsMapByFullPath = _.keyBy(candidates, (unit) => {
    return `${unit.parentPath === "/" ? "" : unit.parentPath}/${unit.name}`;
  });

  if (rootUnit) {
    unitsMapByFullPath[`/${rootUnit.name}`] = rootUnit;
  }

  for (const c in candidates) {
    const item = candidates[c];

    item.fullPath = `${item.parentPath === "/" ? "" : item.parentPath}/${
      item.name
    }`;
    item.isOnboarded = onboardedExtUnitIds.indexOf(item.extId) !== -1;

    //
    item.parentUnit = unitsMapByFullPath[item.parentPath];
    item.directChildUnits = [];
    item.selectedDirectCount = 0;
    item.selectedPartialDirectCount = 0;
    item.selectableDirectChildUnitCount = 0;

    // default checkbox status
    item.checkboxStatus = "NOT_SELECTED";

    // Portfolio
    const {
      gptReqPercentage,
      ampReqPercentage,
      dailyCompatibleAvgReq,
      othersReqPercentage,
      dailyTotalAvg,
      gptReq,
      ampReq,
      othersReq,
    } = _formatPortfolio(item);

    item.gptReqPercentage = gptReqPercentage;
    item.ampReqPercentage = ampReqPercentage;
    item.dailyCompatibleAvgReq = dailyCompatibleAvgReq;

    // used in tooltip
    item.othersReqPercentage = othersReqPercentage;
    item.dailyTotalAvg = dailyTotalAvg;
    item.gptReq = gptReq;
    item.ampReq = ampReq;
    item.othersReq = othersReq;
  }

  for (let c of candidates) {
    if (c.parentUnit) {
      c.parentUnit.directChildUnits.push(c);
    }
  }

  for (let c of candidates) {
    for (let dc of c.directChildUnits) {
      if (dc.hasChildren || (dc.isCompatible && !dc.isOnboarded)) {
        ++c.selectableDirectChildUnitCount;
      }
    }
  }

  if (rootUnit) {
    for (let dc of rootUnit.directChildUnits) {
      if (dc.hasChildren || (dc.isCompatible && !dc.isOnboarded)) {
        ++rootUnit.selectableDirectChildUnitCount;
      }
    }
  }

  // Sort candidates!
  candidates = _.orderBy(
    candidates,
    ["hasChildren", "isCompatible", "dailyCompatibleAvgReq"],
    ["desc", "desc", "desc"]
  );

  return candidates;
}

// const REQ_TYPE = {
//   0: "Unknown",
//   1: "GPT",
//   2: "AMP",
//   3: "GPT Lite",
//   4: "APP",
//   5: "Video",
// };
function _formatPortfolio(item) {
  // Important! portfolio schema is fixed!
  let reqStatPortfolio = item.portfolio[0];
  let gptReq = 0;
  let ampReq = 0;
  let othersReq = 0;
  let dailyCompatibleAvgReq = 0;
  let dailyTotalAvg = 0;

  for (let i = 0; i < reqStatPortfolio.length; ++i) {
    let reqPortfolio = reqStatPortfolio[i];
    let reqType = reqPortfolio[0];
    let isCompatible = reqPortfolio[1];
    let dailyReq = reqPortfolio[2];

    dailyTotalAvg += dailyReq;
    if (isCompatible) {
      dailyCompatibleAvgReq += dailyReq;
    }

    if (reqType === 1) {
      gptReq += dailyReq;
    } else if (reqType === 2) {
      ampReq += dailyReq;
    } else {
      othersReq += dailyReq;
    }
  }
  let gptReqPercentage = (gptReq / dailyCompatibleAvgReq) * 100;
  let ampReqPercentage = (ampReq / dailyCompatibleAvgReq) * 100;
  let othersReqPercentage = (othersReq / dailyCompatibleAvgReq) * 100;

  // if (!item.isCompatible) {
  //   gptReqPercentage = 0;
  //   ampReqPercentage = 0;
  //   dailyCompatibleAvgReq = 0;
  // }

  return {
    gptReqPercentage,
    ampReqPercentage,
    dailyCompatibleAvgReq,
    othersReqPercentage,
    dailyTotalAvg,
    gptReq,
    ampReq,
    othersReq,
  };
}
