import React from "react";

import CommonStyle from "../../../common/styles/common.css";

import YbLoading from "common/components/yb-loading";
import FilterHeader from "../components/filter-header";
import SummarySection from "../components/summary-section";
import InventorySection from "../components/inventory-section";
import TrendSection from "../components/trend-section";

import { fetchRespJson } from "../helpers/api-helper";
import { DATE_RANGE_TYPE, getDateRangeByType } from "../helpers/date-helper";
import {
  transformHomeDashboardData,
  calculateInventoryData,
  calculateSummaryData,
  calculateDistributionData,
  calculateTrendData,
} from "../helpers/reports-helper";

function _convertDateToYMD(date) {
  const dd = new Date(date);
  let d = dd.getDate();
  let m = dd.getMonth() + 1; // Month from 0 to 11
  let y = dd.getFullYear();
  let finalString = `${y}-${m <= 9 ? "0" + m : m}-${d <= 9 ? "0" + d : d}`;
  return finalString;
}

class HomeDashboardView extends React.Component {
  constructor(props) {
    super(props);

    const { startDate, endDate } = getDateRangeByType(DATE_RANGE_TYPE.DEFAULT);

    this.state = {
      isLoading: false,
      errMsg: null,

      homeDashboardData: null,

      filteredNetworks: null,
      selectedNetworkId: -1, // Default: All networks
      selectedYieldSetId: -1, // All YieldSets
      selectedCurrency: "USD",

      // date filter!!!!
      startDate,
      endDate,
    };

    // this.handlePublisherChanged = this.handlePublisherChanged.bind(this);
    this.handleInventoryFilterChanged =
      this.handleInventoryFilterChanged.bind(this);
    this.handleDateRangeFilterChanged =
      this.handleDateRangeFilterChanged.bind(this);
    this.handleCurrencyChanged = this.handleCurrencyChanged.bind(this);
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    try {
      const hData = await fetchRespJson(this.props.reportsUrl);
      const homeDashboardData = transformHomeDashboardData(hData);

      if (!homeDashboardData) {
        throw new Error("No Data");
      }

      console.log(homeDashboardData);

      const {
        // publishers,
        gamNetworks,
      } = homeDashboardData;

      // For Reseller (TODO)
      // const filteredNetworks = _.filter(gamNetworks, { pubId: selectedPubId });

      const currencies = _.keys(homeDashboardData.exchangeRates);
      const exchangeRates =
        homeDashboardData.exchangeRates[this.state.selectedCurrency];

      // Preselect the only network, otherwise "All"
      const selectedNetworkId =
        gamNetworks.length === 1 ? _.get(gamNetworks, [0, "id"], -1) : -1;

      this.setState({
        homeDashboardData,
        currencies,
        exchangeRates,
        // selectedPubId,
        filteredNetworks: gamNetworks,
        selectedNetworkId,
        selectedYieldSetId: -1, // All YieldSets
        inventoryData: calculateInventoryData({
          homeDashboardData,
          selectedNetworkId: this.state.selectedNetworkId,
          selectedYieldSetId: this.state.selectedYieldSetId,
        }),
        summaryData: calculateSummaryData({
          homeDashboardData,
          selectedNetworkId: this.state.selectedNetworkId,
          selectedYieldSetId: this.state.selectedYieldSetId,
          startDate: this.state.startDate,
          endDate: this.state.endDate,
          exchangeRates,
        }),
        distributionData: calculateDistributionData({
          homeDashboardData,
          selectedNetworkId: this.state.selectedNetworkId,
          selectedYieldSetId: this.state.selectedYieldSetId,
          startDate: this.state.startDate,
          endDate: this.state.endDate,
          exchangeRates,
        }),
        trendData: calculateTrendData({
          homeDashboardData,
          selectedNetworkId: this.state.selectedNetworkId,
          selectedYieldSetId: this.state.selectedYieldSetId,
          startDate: this.state.startDate,
          endDate: this.state.endDate,
          exchangeRates,
        }),
      });
    } catch (err) {
      console.log(err);
      this.setState({ errMsg: "Unable to get home dashboard reports." });
    }

    this.setState({ isLoading: false });
  }

  handleInventoryFilterChanged({ selectedNetworkId, selectedYieldSetId }) {
    console.log(
      "Inventory Filter changed: ",
      selectedNetworkId,
      selectedYieldSetId
    );
    this.setState({
      selectedNetworkId,
      selectedYieldSetId,
      inventoryData: calculateInventoryData({
        homeDashboardData: this.state.homeDashboardData,
        selectedNetworkId,
        selectedYieldSetId,
      }),
      summaryData: calculateSummaryData({
        homeDashboardData: this.state.homeDashboardData,
        selectedNetworkId,
        selectedYieldSetId,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        exchangeRates: this.state.exchangeRates,
      }),
      distributionData: calculateDistributionData({
        homeDashboardData: this.state.homeDashboardData,
        selectedNetworkId,
        selectedYieldSetId,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        exchangeRates: this.state.exchangeRates,
      }),
      trendData: calculateTrendData({
        homeDashboardData: this.state.homeDashboardData,
        selectedNetworkId,
        selectedYieldSetId,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        exchangeRates: this.state.exchangeRates,
      }),
    });
  }

  handleDateRangeFilterChanged({ startDate, endDate }) {
    // Important! Convert a date object (with timezone) to just YYYY-MM-DD format
    var sd = _convertDateToYMD(startDate);
    var ed = _convertDateToYMD(endDate);

    console.log("Date Range Filter changed: ", sd, ed);
    this.setState({
      startDate: sd,
      endDate: ed,
      summaryData: calculateSummaryData({
        homeDashboardData: this.state.homeDashboardData,
        selectedNetworkId: this.state.selectedNetworkId,
        selectedYieldSetId: this.state.selectedYieldSetId,
        startDate: sd,
        endDate: ed,
        exchangeRates: this.state.exchangeRates,
      }),
      distributionData: calculateDistributionData({
        homeDashboardData: this.state.homeDashboardData,
        selectedNetworkId: this.state.selectedNetworkId,
        selectedYieldSetId: this.state.selectedYieldSetId,
        startDate: sd,
        endDate: ed,
        exchangeRates: this.state.exchangeRates,
      }),
      trendData: calculateTrendData({
        homeDashboardData: this.state.homeDashboardData,
        selectedNetworkId: this.state.selectedNetworkId,
        selectedYieldSetId: this.state.selectedYieldSetId,
        startDate: sd,
        endDate: ed,
        exchangeRates: this.state.exchangeRates,
      }),
    });
  }

  handleCurrencyChanged(currency) {
    console.log("Currency changed: ", currency);
    const {
      homeDashboardData,
      startDate,
      endDate,
      selectedNetworkId,
      selectedYieldSetId,
    } = this.state;
    const exchangeRates = homeDashboardData.exchangeRates[currency];
    this.setState({
      selectedCurrency: currency,
      exchangeRates,
      startDate,
      endDate,
      summaryData: calculateSummaryData({
        homeDashboardData: homeDashboardData,
        selectedNetworkId: selectedNetworkId,
        selectedYieldSetId: selectedYieldSetId,
        startDate,
        endDate,
        exchangeRates,
      }),
      distributionData: calculateDistributionData({
        homeDashboardData,
        selectedNetworkId,
        selectedYieldSetId,
        startDate,
        endDate,
        exchangeRates,
      }),
      trendData: calculateTrendData({
        homeDashboardData,
        selectedNetworkId,
        selectedYieldSetId,
        startDate,
        endDate,
        exchangeRates,
      }),
    });
  }

  render() {
    const {
      isLoading,
      errMsg,
      homeDashboardData,
      // selectedPubId,
      filteredNetworks,
      selectedNetworkId,
      selectedYieldSetId,
      inventoryData,
      summaryData,
      startDate,
      endDate,
      distributionData,
      trendData,
      currencies,
      selectedCurrency,
    } = this.state;

    if (isLoading) {
      return (
        <div className={CommonStyle.textAlignCenter}>
          <YbLoading></YbLoading>
        </div>
      );
    }

    if (errMsg) {
      return <div>{errMsg}</div>;
    }

    if (!homeDashboardData) {
      return "No Data";
    }

    return (
      <React.Fragment>
        <div style={styles.fixedWidth}>
          <div
            className={CommonStyle.pageTitle}
            style={{ marginLeft: "-16px", marginTop: "8px" }}
          >
            Home Dashboard
          </div>
        </div>

        <div className="sticky-top">
          <FilterHeader
            networks={filteredNetworks}
            selectedNetworkId={selectedNetworkId}
            selectedYieldSetId={selectedYieldSetId}
            yieldSets={homeDashboardData.yieldSets}
            handleInventoryFilterChanged={this.handleInventoryFilterChanged}
            currencies={currencies}
            selectedCurrency={selectedCurrency}
            handleCurrencyChanged={this.handleCurrencyChanged}
            startDate={startDate}
            endDate={endDate}
            handleDateRangeFilterChanged={this.handleDateRangeFilterChanged}
          ></FilterHeader>
        </div>

        {/* <div style={{ margin: "24px 84px" }}> */}
        <div style={styles.fixedWidth}>
          <InventorySection
            updatedTime={homeDashboardData.updatedTime}
            inventoryData={inventoryData}
            selectedYieldSetId={selectedYieldSetId}
          ></InventorySection>

          <SummarySection
            selectedNetworkId={selectedNetworkId}
            selectedYieldSetId={selectedYieldSetId}
            startDate={startDate}
            endDate={endDate}
            summaryData={summaryData}
            distributionData={distributionData}
            selectedCurrency={selectedCurrency}
          ></SummarySection>

          <TrendSection
            selectedNetworkId={selectedNetworkId}
            selectedYieldSetId={selectedYieldSetId}
            startDate={startDate}
            endDate={endDate}
            reports={trendData}
            summaryData={summaryData}
            selectedCurrency={selectedCurrency}
          ></TrendSection>

          {/* 
          <UserInfoContext.Consumer>
            {({ userInfo }) => {
              console.log(userInfo);
              return <div>hi</div>;
            }}
          </UserInfoContext.Consumer> */}
        </div>
      </React.Fragment>
    );
  }
}

const styles = {
  fixedWidth: {
    padding: "8px 24px",
    maxWidth: "1280px",
    margin: "auto",
  },
};

export default HomeDashboardView;
