import gql from "graphql-tag";

export const GET_USER_INFO = gql`
  query GET_USER_INFO {
    me {
      userId
      email
      publisher {
        publisherId
        name
        type
        registered
        geoCountry {
          name
          countryId
          alpha2
        }
        billingSetting {
          activeBillingProfile {
            profileId
            method
          }
        }
        accountType
        reseller {
          publisherId
          name
        }
      }
    }
    publisherAccountStatus {
      status {
        trialStatus {
          remainingDays
          billableStartDate
        }
      }
    }
    accountConfig {
      config
    }
  }
`;
